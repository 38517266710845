<!-- Header -->
<div class="p-5 text-center bg-gradient-animation bg-image" style="height: 350px;">
  <div class="mask">
    <div class="d-flex justify-content-center align-items-center h-100">
      <div class="text-white">
        <h3 class="display-3 mb-3">Support Center</h3>
      </div>
    </div>
  </div>
</div>
<section class="">
  <div class="container text-center text-md-start mt-5">
    <!-- Grid row -->
    <div class="row mt-3">
      <!-- Grid column -->
      <div class="col-md-12 col-lg-12 col-xl-12 mx-auto mb-12">
        <!-- Content -->
        <div class="row">
          <div class="col-md-8 col-lg-8 col-xl-8">
            <div class="card">
              <div class="card-body" *ngIf="currentView==='search'" [@fadeInEnter]>
                <h5 class="card-title">Frequently Asked Questions</h5>
                <p class="card-text">Find an answer to common problems.</p>
                <app-search engine="faqs" (output)="openArticle($event)"></app-search>

              </div>
              <div class="card-body" *ngIf="currentView==='article'"  [@fadeInEnter]>
                <div (click)="toSearchList()" class="pointer hoverUnderline"><i class="fas fa-arrow-left"></i> Back to search</div><br>
                <app-support-detail [articleId]="selectedArticleId"></app-support-detail>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-lg-4 col-xl-4">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Contact Information</h5>
                <p>If you need further assistance, you can reach out to us through the following channels:</p>
                <ul class="list-unstyled">
                  <!-- <li>
                    <button (click)="contactSupport()" type="button" class="btn btn-primary btn-rounded">Submit Request</button>
                  </li><br> -->
                  <li>Email: <a href="mailto:support@eventodyssey.com">support&#64;eventodyssey.com</a></li>
                  <li>Phone: <a href="tel:+13174830027">+1 (317) 483-0027</a></li>
                </ul>
              </div>
            </div>
            <div class="mt-4">
              <app-trustpilot-badge></app-trustpilot-badge>
            </div>

          </div>

        </div>
      </div>
      <!-- Grid column -->
    </div>
    <!-- Grid row -->
  </div>
</section>
