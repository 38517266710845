<br><br><br><br><br><br>
<div class="container text-center">
  <h1>404 Error</h1>
  <h5>The page you're looking for cannot be found.</h5>
  <p>The page you were trying to access may have been moved or may never have existed. <span *ngIf="userLoggedIn">Our team has been notified and is looking into the issue.</span></p>
  <div class="text-center">
    <button (click)="goBack()" class="btn btn-medium btn-rounded me-3 mb-2 btn-lg text-capitalize"><i class="fas fa-arrow-left"></i> Go Back</button>
    <button [routerLink]="'/home'" class="btn btn-primary btn-rounded me-3 mb-2 btn-lg text-capitalize">Home</button>
  </div>
  <ng-container *ngIf="userLoggedIn; else notLoggedIn">
    <ng-container *ngIf="loading; else supportCodeFetched">
      <ng-container *ngIf="error; else displayLoading">
        <app-https-error [error]="error"></app-https-error>
      </ng-container>
      <ng-template #displayLoading>
        <div class="skeleton" style="height: 120px; width: 80%; margin-left: 10%"></div>
      </ng-template>
    </ng-container>
    <ng-template #supportCodeFetched>
      <div>
        <div class="card border border-dark shadow-0 mb-3 mt-4">
          <div class="card-body">
            <div class="card-text">
              <div></div>
              <div class="text-start small">
                <div>Error Identifier: <span class="text-muted">{{submittedError.id}}</span></div>
                <div>User ID: <span class="text-muted">{{submittedError.uid}}</span></div>
                <div>Attempted Route: <span class="text-muted">{{attemptedUrl}}</span></div>
                <div>Timestamp: <span class="text-muted">{{submittedError.created | date:'long'}}</span></div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center"><span class="small">If you need assistance regarding this issue, please <a class="pointer" (click)="contactSupport()">click here</a> to contact support.</span></div>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #notLoggedIn class="mt-1">
    Login to report this issue
  </ng-template>
</div>
<br><br><br><br><br><br>