<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Redeem Ticket<span *ngIf="selectedSeats.length>1">s</span></h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
    <ng-container *ngIf="loading; else displayCheckout">
        <app-loading></app-loading>
    </ng-container>
    <ng-template #displayCheckout>
        <ng-container *ngIf="!paymentSuccessful; else success">
            <ng-container *ngIf="!paymentError; else displayError">
                <div class="text-center" *ngIf="countdownMinutes > 0 || countdownSeconds > 0">
                    Seats reserved: Please order within {{ countdownMinutes }}:{{ countdownSeconds | number: '2.0' }}.
                </div>
                <div class="text-center" *ngIf="countdownMinutes <= 0 && countdownSeconds <= 0">
                    <app-notice
                        content="Reservation expired! You can continue making a purchase, but it may fail as your seats are no longer guaranteed."
                        type="danger"></app-notice>
                </div>
                <mdb-stepper class="mb-2" #stepper [orientation]="'vertical'" [linear]="true"
                    (stepChange)="stepChange($event)">
                    <mdb-step [name]="getSelectSeatTypeStepName()" [stepForm]="selectProductForm">
                        <form [formGroup]="selectProductForm"><input type="hidden" formGroupName="valid"></form>
                        <app-select-seat-prices [selectedSeats]="selectedSeats"
                            (isValid)="validateselectProduct($event)"
                            (output)="outputselectProduct($event)"></app-select-seat-prices>
                    </mdb-step>
                </mdb-stepper>
                <div class="modal-footer">
                    <button (click)="purchase()" [disabled]="purchasing" type="button" class="btn btn-primary btn-rounded">
                        <span *ngIf="purchasing">Redeeming&nbsp;&nbsp;<span
                                class="spinner-border spinner-border-sm me-2"></span></span>
                        <span *ngIf="!purchasing">Redeem</span>
                    </button>
                </div>
            </ng-container>
            <ng-template #displayError>
                <app-https-error [error]="paymentError"></app-https-error>
            </ng-template>
        </ng-container>
        <ng-template #success>
            <app-payment-successful></app-payment-successful>

            <div class="modal-footer">
                <button (click)="modalRef.close()" type="button" class="btn btn-grey btn-rounded">Close</button>
                <a (click)="modalRef.close()" [routerLink]="'/tickets'"
                    class="btn btn-primary btn-rounded text-start"><i class="fas fa-ticket-alt me-2"></i> View
                    Tickets</a>
            </div>

        </ng-template>
    </ng-template>




</div>