<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Download Video</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<ng-container *ngIf="!isLoadingPrecheck; else displayLoading">
  <ng-container *ngIf="preCheckPassed; else preCheckFailed">
    <div class="modal-body">
      <ng-container *ngIf="linkedOpen; else showCurrentDownloads">
        <div class="text-center">
          <i class="fas fa-file-download fa-4x mt-2 mb-4"></i>
          <h5 style="margin-bottom: 0px; padding-bottom: 0px;">Download Started...</h5>
          <p class="small text-muted">
            If the file opened in a new tab, right-click it and select "Save As" to download.
          </p>
        </div>
      </ng-container>
      <ng-template #showCurrentDownloads>
        <div class="text-center">
          <i class="fas fa-file-download fa-4x mt-2 mb-4"></i>
          <h5 style="margin-bottom: 0px; padding-bottom: 0px;"><span>{{remainingDownloads}} downloads</span> remaining</h5>
          <span class="small text-muted">Your purchase entities you to {{totalEntitledDownloads}} downloads (per clip).</span>
        </div>
      </ng-template>
    </div>
    <div class="modal-footer">
      <button [disabled]="isSaving||linkedOpen" (click)="submit()" type="button"
        class="btn btn-primary btn-rounded text-capitalize">
        <span *ngIf="isSaving"><span class="spinner-border spinner-border-sm me-2" role="status"
            aria-hidden="true"></span> Starting Download</span>
        <span *ngIf="!isSaving">Download</span>
      </button>
    </div>
  </ng-container>
  <ng-template #preCheckFailed>
    <div class="modal-body">
      <ng-container *ngIf="!error; else displayError">
        <div class="modal-body">
          <div class="row mb-2">
            <h4>You don't have access to download this video</h4>
            <p>Your purchase entities you to {{totalEntitledDownloads}} downloads (per clip), which you already used.</p>
          </div>

        </div>
      </ng-container>
      <ng-template #displayError>
        <app-https-error [error]="error"></app-https-error>
      </ng-template>
    </div>
  </ng-template>
</ng-container>
<ng-template #displayLoading>
  <div class="modal-body">
    <ng-container *ngIf="!error; else displayError">
      <app-loading></app-loading>
    </ng-container>
    <ng-template #displayError>
      <app-https-error [error]="error"></app-https-error>
    </ng-template>
  </div>
</ng-template>
