import { ThisReceiver } from '@angular/compiler';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { MdbStepperComponent } from 'mdb-angular-ui-kit/stepper';
import { StripeFactoryService, StripeInstance } from 'ngx-stripe';
import { Community } from 'src/app/interfaces/community';
import { BackendService } from 'src/app/services/backend.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-purchase-seats',
  templateUrl: './purchase-seats.component.html',
  styleUrls: ['./purchase-seats.component.scss']
})
export class PurchaseSeatsComponent implements OnInit {
  countdownMinutes: number;
  countdownSeconds: number;
  @Input() selectedSeats
  @Input() addonsDetails
  @Input() eventDetails
  @Input() communityDetails: Community


  @ViewChild('stepper') stepper!: MdbStepperComponent;
  step: number = 1
  paymentSuccessful: boolean = false;
  paymentError: string = ''
  paymentErrorType: string = 'warning'
  allStepsValid: boolean = false;
  allStepsForm: FormGroup;
  selectProductForm: FormGroup;
  selectProductValid: boolean = false
  selectAddonsForm: FormGroup;
  selectAddonsValid: boolean = false
  selectPaymentForm: FormGroup;
  selectPaymentValid: boolean = false
  checkoutForm: FormGroup;
  selectProduct: any = {
    price: {},
    product: {}
  }
  selectAddons: any = {
    selectedAddons: [],
    totalPrice: 0
  }
  checkout: any = {};
  paymentMethod: any = {};
  purchasing: boolean = false
  stripe: StripeInstance;

  additionalInfo: any = {};
  additionalInfoForm: FormGroup;
  additionalInfoValid: boolean = false

  loading: boolean = true
  constructor(
    public modalRef: MdbModalRef<PurchaseSeatsComponent>,
    private functions: AngularFireFunctions,
    private stripeFactory: StripeFactoryService,
    private backendService: BackendService,
    private analytics: AngularFireAnalytics
  ) { }

  ngOnInit(): void {
    console.log(this.addonsDetails)
    console.log('lets reserve the seats')
    this.reserveSeats()
  }

  reserveSeats() {
    this.loading = true
    this.backendService.reserveCommunityEventSeats(this.communityDetails.id, this.eventDetails.id, { requestedSeats: this.selectedSeats }).subscribe({
      next: async (res) => {
        if (res.status === 'success') {
          this.loading = false
          this.setupForms();
          this.startCountdown(res.expTime, res.serverTime)
        } else {
          this.seatsUnavailable()
        }

      },
      error: (err) => {
        console.warn(err)
        return
      },
    });
  }

  setupForms() {
    this.selectProductForm = new FormGroup({
      valid: new FormControl(null, Validators.required)
    });
    this.selectAddonsForm = new FormGroup({
      valid: new FormControl(null, Validators.required)
    });
    this.selectProductForm = new FormGroup({
      valid: new FormControl(null, Validators.required)
    });
    this.selectPaymentForm = new FormGroup({
      valid: new FormControl(null, Validators.required)
    });
    this.additionalInfoForm = new FormGroup({
      valid: new FormControl(null, Validators.required)
    });
    this.checkoutForm = new FormGroup({
      valid: new FormControl(null, Validators.required)
    });
    this.allStepsForm = new FormGroup({
      selectAddonsForm: this.selectAddonsForm,
      selectProductForm: this.selectProductForm,
      selectPaymentForm: this.selectPaymentForm,
      additionalInfoForm: this.additionalInfoForm,
      checkoutForm: this.checkoutForm,
    });
  }


  validateselectProduct(isValid) {
    isValid ? this.allStepsForm.controls.selectProductForm.get('valid').setValue("valid") : this.allStepsForm.controls.selectProductForm.get('valid').setValue(null);
    if (!isValid) {
      this.selectProduct = {}
      this.selectProductValid = false
    } else {
      this.selectProductValid = true
    }
    this.validateAllSteps();
  }

  outputselectProduct($event) {
    this.selectProduct = $event;
  }

  validateselectAddons(isValid) {
    isValid ? this.allStepsForm.controls.selectAddonsForm.get('valid').setValue("valid") : this.allStepsForm.controls.selectAddonsForm.get('valid').setValue(null);
    if (!isValid) {
      this.selectAddons = {}
      this.selectAddonsValid = false
    } else {
      this.selectAddonsValid = true
    }
    this.validateAllSteps();
  }

  outputselectAddons($event) {
    this.selectAddons = $event;
  }

  validateselectPayment(isValid) {
    isValid ? this.allStepsForm.controls.selectPaymentForm.get('valid').setValue("valid") : this.allStepsForm.controls.selectPaymentForm.get('valid').setValue(null);
    if (!isValid) {
      this.paymentMethod = {}
      this.selectPaymentValid = false
    } else {
      this.selectPaymentValid = true
    }
    this.validateAllSteps();
  }

  outputselectPayment($event) {
    this.paymentMethod = $event;
  }


  validateadditionalInfo(isValid) {
    isValid ? this.allStepsForm.controls.additionalInfoForm.get('valid').setValue("valid") : this.allStepsForm.controls.additionalInfoForm.get('valid').setValue(null);
    if (!isValid) {
      this.additionalInfo = {}
      this.additionalInfoValid = false
    } else {
      this.additionalInfoValid = true
    }
    this.validateAllSteps();
  }

  outputadditionalInfo($event) {
    this.additionalInfo = $event;
  }


  validatecheckout(isValid) {
    isValid ? this.allStepsForm.controls.checkoutForm.get('valid').setValue("valid") : this.allStepsForm.controls.checkoutForm.get('valid').setValue(null);
    if (!isValid) this.checkout = {};
    this.validateAllSteps();
  }

  outputcheckout($event) {
    this.checkout = $event;
  }

  validateAllSteps() {
    this.allStepsValid = this.allStepsForm.valid;
  }

  stepChange(event) {
    this.clearPaymentError()
    this.step = event.activeStepIndex + 1
  }

  next() {
    this.stepper.next()
  }

  setStepperToPaymentMethod() {
    this.stepper.setNewActiveStep(1)
  }

  previous() {
    this.stepper.previous()
  }

  async purchase() {
    this.paymentError = ''
    this.purchasing = true

    let lineItems = this.selectProduct.seats
    const addonId = this.ranId(5)

    // Enhance Selected Seat Details
    lineItems.forEach((selectedSeat: any) => {
      selectedSeat.lineItemType = 'seat';
      selectedSeat.eventId = this.eventDetails.id;
      selectedSeat.communityId = this.communityDetails.id;
    });
    await this.selectAddons.selectedAddons.forEach(async (selectedAddon: any) => {
      for (let i = 0; i < selectedAddon.amount; i++) {
        console.log(i, 'of', selectedAddon.amount)
        lineItems.push({
          lineItemType: 'seat',
          eventId: this.eventDetails.id,
          communityId: this.communityDetails.id,
          id: `VIP_${addonId}_${(i+1)}`,
          price: selectedAddon.price,
          row: 'VIP',
          seat: `${addonId}-${(i+1)}`,
          section: 'VIP Experience',
          type: 'VIP'
        })
      }
    })
    console.log(lineItems)
    this.backendService.createEventTicketPaymentIntent(this.communityDetails.id, this.eventDetails.id, { requestedSeats: this.selectedSeats, lineItems: lineItems, paymentMethod: this.paymentMethod, additionalInfo: this.additionalInfo }).subscribe({
      next: async (res) => {
        console.log(res)
        this.handlePaymentIntent(res)
      },
      error: (err) => {
        console.warn(err)
        this.handlePaymentIntent(err)
        return
      },
    });

  }

  async handlePaymentIntent(paymentIntent) {
    this.stripe = await this.stripeFactory.create(environment.stripe, { stripeAccount: paymentIntent.stripeId })
    switch (paymentIntent.status) {
      case 'succeeded':
      case 'processing':
        this.purchasing = false
        this.paymentSuccessful = true
        break;
      case 'requires_action':
        this.paymentError = 'Your payment method requires additional information to process the payment successfully.'
        this.stripe.confirmCardPayment(paymentIntent.client_secret).subscribe(result => {
          if (result.error) { this.handlePaymentError(result.error.message, 'danger') }
          else {
            this.handlePaymentIntent(result.paymentIntent)
          }
        });

        break;
      case 'error':
      case 'requires_payment_method':
        this.handlePaymentError(paymentIntent.error.message)
        break;
      case 'seats_unavailable':
        this.handlePaymentError('The seats you selected are no longer available. Your payment method was not charged.', 'danger')
        break;
    }
  }

  handlePaymentError(message: string, type?: string) {
    if (!type) { type = 'warning' }
    if (String(message).includes('Firebase')) {
      message = String(message).replace('Firebase: ', '')
      message = String(message).replace(/Firebase/g, '')
    }
    if (String(message).includes('declined')) { type = 'danger' }
    this.purchasing = false
    this.paymentErrorType = type
    this.paymentError = message
  }

  clearPaymentError() {
    this.paymentError = ''
    this.paymentErrorType = 'warning'
  }

  startCountdown(expTime: string, serverTime: string): void {
    const serverTimestamp = new Date(serverTime).getTime();
    const clientTimestamp = new Date().getTime();

    // Calculate the offset between server and client time
    const timeOffset = clientTimestamp - serverTimestamp;

    const reservationTimestamp = new Date(expTime).getTime();
    let countdownMilliseconds = Math.max(0, reservationTimestamp - clientTimestamp + timeOffset);

    const updateCountdown = (): void => {
      const currentTime = new Date().getTime();
      countdownMilliseconds = Math.max(reservationTimestamp - currentTime + timeOffset, 0);
      this.countdownMinutes = Math.floor(countdownMilliseconds / 60000);
      this.countdownSeconds = Math.floor((countdownMilliseconds % 60000) / 1000);

      if (this.countdownMinutes === 0 && this.countdownSeconds === 0) {
        this.handleCountdownExpired();
      } else {
        setTimeout(updateCountdown, 1000);
      }
    };

    updateCountdown(); // Start the countdown
  }





  handleCountdownExpired(): void {
    // Perform any other necessary actions
  }

  seatsUnavailable() {
    this.modalRef.close({ message: 'seatsUnavailable' })
  }

  getSelectSeatTypeStepName() {
    return this.selectedSeats.length > 1 ? 'Confirm Seat Selection' : 'Confirm Seat Selection';
  }

  ranId(num: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const charactersLength = characters.length;
    for (let i = 0; i < num; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

calculateMaxLimit() {
  if((this.eventDetails && this.eventDetails.issuedTickets)) {
    return ((this.selectProduct.seats.length)+(this.eventDetails.issuedTickets.length))
  } else {
    return this.selectProduct.seats.length
  }
}

}
