import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { BehaviorSubject, Subject } from 'rxjs';
import { AcceptTermsComponent } from '../components/modals/accept-terms/accept-terms.component';
import { BackendService } from './backend.service';
import { IntercomService } from './intercom.service';

@Injectable({
  providedIn: 'root'
})
export class JoinedCommunitiesService {
  launchedTermsModalRef: boolean = false
  termsModalRef: MdbModalRef<AcceptTermsComponent> | null = null;
  private globalAdmin = false
  private joinedCommunities = [];
  private joinedAdminCommunities = [];
  globalAdminChanged = new BehaviorSubject<any>({ 'loading': true });
  joinedCommunitiesChanged = new BehaviorSubject<any[]>([{ 'loading': true }]);
  joinedAdminCommunitiesChanged = new BehaviorSubject<any[]>([{ 'loading': true }]);

  constructor(private backendService: BackendService, private modalService: MdbModalService, private intercomService: IntercomService) { }

  async fetchJoinedCommunities() {
    this.backendService.getUserCommunities().subscribe({
      next: (res) => {
        this.intercomService.triggerInitialization(res.userHash)
        if (!res.termsAcceptance) { this.launchTermsModal() }
        if(res.globalAdmin) { this.globalAdmin = true }
        this.joinedCommunities = res.communities
        this.joinedAdminCommunities = res.adminCommunities
        this.globalAdminChanged.next(this.globalAdmin)
        this.joinedCommunitiesChanged.next(this.joinedCommunities)
        this.joinedAdminCommunitiesChanged.next(this.joinedAdminCommunities)
        return
      },
      error: (err) => {
        console.log(err)
        return
      },
    });
  }

  launchTermsModal() {
    if (!this.launchedTermsModalRef) {
      this.termsModalRef = this.modalService.open(AcceptTermsComponent, {
        modalClass: 'modal-dialog-scrollable modal-lg',
        ignoreBackdropClick: true,
        data: {
          termsId: 'user-terms-of-service',
          enableCancelButton: false
        }
      })
      this.termsModalRef.onClose.subscribe((message: any) => {
        if (message === 'created') {
          this.fetchJoinedCommunities()
        }
      });
      this.launchedTermsModalRef = true
    }
  }



}
