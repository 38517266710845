<!-- Navbar-->
<nav class="navbar sticky-top navbar-expand-lg navbar-light navToolbar noselect">
  <div class="container-fluid justify-content-between">
    <!-- Left elements -->
    <div class="d-flex">
      <!-- Mobile Nav -->
      <ul class="navbar-nav flex-row d-">
        <li class="nav-item me-3 me-lg-1 active">
          <a (click)="toggle()" class="nav-link pointer">
            <span><i class="fas fa-bars fa-lg"></i></span>
          </a>
        </li>
      </ul>

      <a class="navbar-brand me-2 mt-0 pt-0 mb-0 pb-0 d-flex align-items-center" routerLink="/home">
        <app-logo [height]="20" style="margin-top: 2px;"></app-logo>
      </a>
    </div>
    <!-- Left elements -->

    <!-- Center elements -->
    <ul class="navbar-nav flex-row d-none d-md-flex">
      <li class="nav-item me-3 me-lg-1 active">
        <a class="nav-link" routerLink="/home" title="Home" routerLinkActive="active">
          <span><i class="fas fa-home fa-lg"></i></span>
        </a>
      </li>

      <li *ngIf="userProfile" class="nav-item me-3 me-lg-1">
        <a class="nav-link" routerLink="/videos" title="My Videos" routerLinkActive="active">
          <span><i class="fas fa-video fa-lg"></i></span>
        </a>
      </li>

      <li *ngIf="userProfile" class="nav-item me-3 me-lg-1">
        <a class="nav-link" routerLink="/tickets" title="My Tickets" routerLinkActive="active">
          <span><i class="fas fa-ticket-alt fa-lg"></i></span>
        </a>
      </li>

      <li class="nav-item me-3 me-lg-1">
        <a class="nav-link" routerLink="/communities" title="Communities" routerLinkActive="active">
          <span><i class="fas fa-users fa-lg"></i></span>
          <!-- <span class="badge rounded-pill badge-notification bg-danger">2</span> -->
        </a>
      </li>
    </ul>
    <!-- Center elements -->

    <!-- Right elements -->
    <ul class="navbar-nav flex-row">
      <li *ngIf="loggedIn==='false'" class="nav-item me-3 me-lg-1" (click)="login()">
        <a class="nav-link d-sm-flex align-items-sm-center pointer">
          <i class="fas fa-user-circle"></i>
          <strong class="d-none d-sm-block ms-1">Login / Register</strong>
        </a>
      </li>
      <ng-container *ngIf="loggedIn!=='false'">
        <ng-container *ngIf="userProfile; else loadingProfile">
          <li mdbDropdown class="nav-item me-3 me-lg-1">
            <a class="nav-link d-sm-flex align-items-sm-center" id="navbarDropdownMenuLink" role="button"
              mdbDropdownToggle aria-expanded="false">
              <img [src]="userProfile.profilePicture"
                (error)="userProfile.profilePicture = './assets/user-profile.png'"
                class="rounded-circle" height="22" alt="" loading="lazy" />
              <strong class="d-none d-sm-block ms-1">{{userProfile.given_name}}</strong>
            </a>
            <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
              <li>
                <a class="dropdown-item pointer" (click)="userSettings()"><i class="fas fa-cog"></i>&nbsp;&nbsp;Edit Settings</a>
              </li>
              <li *ngIf="environmentName!=='production'">
                <a class="dropdown-item" style="cursor: pointer" (click)="colorModeToggle()"><i class="fas fa-moon" style="margin-left: 0.2em; margin-right: 0.2em;"></i>&nbsp;&nbsp;Dark Mode</a>
              </li>
              <li *ngIf="environmentName!=='production'">
                <a class="dropdown-item" [routerLink]="'/my-students'"><i class="fas fa-user-graduate" style="margin-left: 0.2em; margin-right: 0.2em;"></i>&nbsp;&nbsp;My Students</a>
              </li>
              <li>
                <a class="dropdown-item" [routerLink]="'/purchases'"><i class="fas fa-dollar-sign" style="margin-left: 0.2em; margin-right: 0.2em;"></i>&nbsp;&nbsp;My Purchases</a>
              </li>
              <li>
                <a class="dropdown-item" (click)="logout()" href="/"><i class="fas fa-sign-out-alt"></i>&nbsp;&nbsp;Logout</a>
              </li>
            </ul>
          </li>
          <li class="nav-item me-3 me-lg-1 active">
            <a (click)="openNotifications()" class="nav-link pointer">
              <span>
                <i class="fas fa-bell fa-lg"></i>
                <span *ngIf="notificationsCount>0" class="badge rounded-pill badge-notification bg-danger">{{notificationsCount}}</span>
              </span>
            </a>
          </li>
        </ng-container>
        <ng-template #loadingProfile>
          <div class="spinner-border spinner-border-sm me-3" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </ng-template>
      </ng-container>
    </ul>
    <!-- Right elements -->
  </div>
</nav>
<!-- Navbar -->
