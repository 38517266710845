<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Payment Details</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">


  <ng-container *ngIf="!loading; else displayLoading">
    <div class="row">
      <div class="mt-3">
        <div class="">
          <div class="d-flex justify-content-between">
            <div class="flex-fill text-start">
              <div class="small mb-1" style="line-height: 1;">Charge ID</div>
              <div>{{chargeDetails.id}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      
      <div class="mt-3">
        <div class="">
          <div class="d-flex justify-content-between">
            <div>
              <div class="small mb-2">Customer Details</div>
              <div class="d-flex align-items-center">
                <img referrerPolicy="no-referrer" [src]="customerDetails.profilePic"
                  (error)="customerDetails.profilePic = './assets/user-profile.png'" alt=""
                  style="width: 45px; height: 45px;" class="rounded-circle" />
                <div class="ms-2">
                  <p class="%T%^mt-0 mb-1" style="line-height: 1;">{{customerDetails.displayName}}</p>
                  <p class="mt-0 mb-0" style="line-height: 1;">{{customerDetails.email}}</p>
                </div>
              </div>
            </div>
            <div class="flex-fill text-end">
              <div class="small mb-1" style="line-height: 1;">Card Details</div>
              <app-card-detail [cardDetails]="chargeDetails.payment_method_details"></app-card-detail>
            </div>
          </div>
        </div>
      </div>


      <div class="mt-4 ps-2 pe-2 me-4">
        <div class="ps-3 pe-3">
          <div class="row border border-3">
            <div class="col-6">
              <div class="small">Item Details</div>
            </div>
            <div class="col-2">
              <div class="small">Price</div>
            </div>
            <div class="col-2">
              <div class="small">Tax</div>
            </div>
            <div class="col-2">
              <div class="small">Total</div>
            </div>
          </div>
          <div *ngFor="let groupedItem of lineItems" class="row border-start border-end border-bottom border-3">
            <div class="row">
              <h5 class="mt-1" style="margin-bottom: 0px; text-transform: capitalize;">{{groupedItem.eventDetails.name}}
              </h5>
              <div class="small">
                {{groupedItem.eventDetails.startTime | date:'fullDate'}} at {{groupedItem.eventDetails.startTime | date:'shortTime'}}
              </div>
            </div>
            <div class="row rowPadding" *ngFor="let item of groupedItem.lineItems">
              <div class="col-6">
                <h6 style="margin-bottom: 0px; text-transform: capitalize;">{{item.type}}</h6>
                <div class="small">
                  <span>Seat: {{item.row}}/{{item.seat}} ({{item.section}})</span>
                </div>
              </div>
              <div class="col-2 text-start">
                {{(item.price/100) | currency}}
              </div>
              <div class="col-2 text-start">
                <ng-container *ngIf="item.tax>0; else valueZero">
                  {{item.tax | currency}}
                </ng-container>
                <ng-template #valueZero>
                  ---
                </ng-template>
              </div>
              <div class="col-2 text-start">
                {{(item.price/100) | currency}}
              </div>
            </div>
          </div>
          <div class="row border-start border-end border-bottom border-3" style="height: 5px;"></div>
          <div class="row border border-3">
            <div class="row rowPadding">
              <div class="col-6">
              </div>
              <div class="col-4">
                Amount Charged
              </div>
              <div class="col-2">
                {{(chargeDetails.amount/100) | currency}}
              </div>
            </div>
            <div class="row rowPadding">
              <div class="col-6">
              </div>
              <div class="col-4">
                Tax
              </div>
              <div class="col-2">
                <ng-container *ngIf="chargeDetails.tax>0; else valueZero">
                  {{chargeDetails.tax | currency}}
                </ng-container>
                <ng-template #valueZero>
                  ---
                </ng-template>
              </div>
            </div>
            <div class="row border-start border-end border-bottom border-3 rowPadding" style="height: 2px;"></div>
            <div class="row rowPadding">
              <div class="col-6">
              </div>
              <div class="col-4">
                Total Fees
              </div>
              <div class="col-2">
                ({{(chargeDetails.totalFees/100) | currency}})
              </div>
            </div>
            <div class="row border-start border-end border-bottom border-3 rowPadding" style="height: 2px;"></div>
            <div class="row rowPadding">
              <div class="col-6">
              </div>
              <div class="col-4">
                Grand Total
              </div>
              <div class="col-2">
                {{(chargeDetails.amount/100) | currency}}
              </div>
            </div>
          </div>
        </div>
      </div>


      </div>


  </ng-container>
  <ng-template #displayLoading>
    <ng-container *ngIf="!error; else displayError">
      <app-loading></app-loading>
    </ng-container>
    <ng-template #displayError>
      <app-https-error [error]="error"></app-https-error>
    </ng-template>
  </ng-template>



</div>
