import { Component, Input, OnInit } from '@angular/core';
import { tadaEnterAnimation } from 'mdb-angular-ui-kit/animations';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { LoginComponent } from '../login/login.component';

@Component({
  selector: 'app-https-error',
  templateUrl: './https-error.component.html',
  animations: [tadaEnterAnimation({duration: 1500, delay: 350}),],
  styleUrls: ['./https-error.component.scss']
})
export class HttpsErrorComponent implements OnInit {
  loginModalRef: MdbModalRef<LoginComponent> | null = null;
  @Input() error
  constructor(
    private modalService: MdbModalService
  ) { }

  ngOnInit(): void {
  }

  
  login() {
    this.loginModalRef = this.modalService.open(LoginComponent, {
      ignoreBackdropClick: true,
    })
  }

}
