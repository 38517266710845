import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-user-tickets-list',
  templateUrl: './user-tickets-list.component.html',
  styleUrl: './user-tickets-list.component.scss'
})
export class UserTicketsListComponent {
  @Input() lookupUid: string
  constructor() { }

  ngOnInit(): void {
  }

}
