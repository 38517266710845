<div class="p-3 text-center bg-gradient-animation bg-image" style="height: 350px;">
  <div class="">
    <div class="d-flex justify-content-center align-items-center h-100">
      <div class="text-white mt-4" style="width: 100%">
        <h1 class="mb-4 px-3">Welcome aboard<span *ngIf="givenName"> {{givenName}}</span>!</h1>
        <!-- <h4 class="mb-5 px-3 text-wrap">
          <span class="typewriter">{{ textToDisplay }}</span>
        </h4> -->
        <div class="px-3 text-wrap">
          <p class="">Search for an event or community below</p>
        </div>
        <div class="app-search-container">
          <app-landing-search class="app-search"></app-landing-search>
        </div>
      </div>
    </div>
  </div>
</div>