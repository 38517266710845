import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  attemptedUrl: string = ''
  authSubscription: Subscription
  userLoggedIn: boolean = false
  loading: boolean = false
  error: any = null
  submittedError
  href: string = ""
  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private backendService: BackendService,
    private afAuth: AngularFireAuth
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Not Found | Event Odyssey')
    this.authSubscription = this.afAuth.authState.subscribe((user) => {
      console.log(user)
      this.userLoggedIn = Boolean(user)
      this.fetchDetails()
    })
  }

  goBack() {
    window.history.go(-2)
  }
  
  fetchDetails() {
    this.route.queryParams.subscribe(params => {
      this.attemptedUrl = params['redirect'];
      if(this.userLoggedIn) {
        this.submitNotFoundError(this.attemptedUrl)
      }
    })
  }

  submitNotFoundError(attemptedUrl: string) {
    this.loading = true
    this.backendService.submitNotFoundError({ attemptedUrl: attemptedUrl }).subscribe({
      next: async (res) => {
        console.log(res)
        this.loading = false
        this.submittedError = res
        this.href = `mailto:support@eventodyssey.com?subject=Page%20Not%20Found&body=Page%20ID:%20${res.id}`
      },
      error: (err) => {
        console.log(err)
        this.error = err.message
        console.warn(err)
        return
      },
    });
  }

  contactSupport() {
    console.log('contact support')
    window.location.href = this.href
  }

}
