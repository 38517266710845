import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { MdbTabsComponent } from 'mdb-angular-ui-kit/tabs';
import { MdbTabChange } from 'mdb-angular-ui-kit/tabs/tabs.component';
import { Community } from 'src/app/interfaces/community';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { EnvironmentService } from 'src/app/services/environment.service';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss']
})
export class CommunityManageComponent implements OnInit {
  error
  loading: boolean = true
  firstTabChange: boolean = true
  communityDetails: Community
  @ViewChild(MdbTabsComponent) tabs: MdbTabsComponent
  isVertical: boolean = true
  environmentName: string
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isVertical = window.innerWidth >= 989;
  }
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private backendService: BackendService,
    private afAuth: AngularFireAuth,
    private titleService: Title,
    private envService: EnvironmentService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.environmentName = this.envService.getEnvironmentName();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.afAuth.authState.subscribe((user) => {
      this.getCommunityDetails()
    })
  }

  getCommunityDetails() {
    const communityId = this.route.snapshot.params['id']

    this.backendService.getCommunityDetails(communityId).subscribe({
      next: (res) => {
        if (res.admin === true) {
        this.loading = false
        this.communityDetails = res
        this.titleService.setTitle(`Manage - ${this.communityDetails.name} | Event Odyssey`)
        this.setActiveSection()
        } else {
          this.error = 'You don\'t have access to this page!'
        }
      },
      error: (err) => {
        console.log(err)
        return
      },
    });
  }

  async setActiveSection() {
    const section = this.route.snapshot.params['section']
    if (this.tabs) {
      let sections = ['welcome', 'settings'];
      if(this.communityDetails.communityType==='School') {
        sections = ['welcome', 'billing', 'events', 'members', 'settings'];
        if(this.environmentName==='production') {
          sections = ['welcome', 'events', 'members', 'settings'];
        }
      } else if(this.communityDetails.communityType==='Independent Videographer') {
        sections = ['welcome', 'billing', 'videos', 'settings'];
      }
      let tabIndex = sections.indexOf(section);
      if (tabIndex !== -1) {
        this.tabs.setActiveTab(tabIndex);
      }
    } else {
      // Keep retrying until tabs available
      setTimeout(() => {
        this.setActiveSection()
      }, 10);
    }

  }

  onTabChange(event: MdbTabChange): void {
    if (this.firstTabChange) { this.firstTabChange = false } else {
      const tabName = event.tab.title.toLowerCase()
      if(tabName==='memberarea') {
        this.router.navigateByUrl('communities/'+this.communityDetails.id)
      } else {
        this.location.replaceState('/communities/' + this.communityDetails.id + '/manage/' + tabName)
      }
    }
  }

}
