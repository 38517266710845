// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  domain: 'test.eventodyssey.com',
  intercomId: 'w2w2s9if',
  backendUrl: 'https://test.eventodyssey.com/api',
  name: 'dev',
  stripe: 'pk_test_51MKaGHEaZZl95VDTl2DTg6JFHGkeHEW4KieNyM71Zx0HQ9TBkb3LsvmPNeer2pxFvG2GMekikHuvXYtJmCGB4zCt00F6SX9oL6',
  production: true,
  gcpRegion: 'us-central1',
  muxEnv: 'ionraua9fqdl4qjjb97ap9rag',
  defaultMapId: '2ea2d13b13c83fbf',
  googleMapsKey: 'AIzaSyDeHqCSmQGza53z7_oNJbzSEhXNK4uCAF8',
  googleCastAppId: 'DFE085E1',
  firebaseConfig: {
    apiKey: "AIzaSyCNtewoBoygtIw5eQ21cF1NSAUJ57yOr3g",
    authDomain: "videostore-dev.firebaseapp.com",
    projectId: "videostore-dev",
    storageBucket: "storage.test.eventodyssey.com",
    messagingSenderId: "896792247478",
    appId: "1:896792247478:web:d32d35a5d57e95b932fa17",
    measurementId: "G-BRK9BSRMHP"
  },
  metaPixelId: '478578448440873',
  toastOptions: {
    generic: {
      position: 'top-right',
      delay: 4000,
      autohide: true,
      stacking: true,
      data: {
        sound: 'notification',
        content: '',
        type: 'generic'
      },
    },
    notification: {
      position: 'top-right',
      delay: 6000,
      autohide: true,
      stacking: true,
      data: {
        sound: 'notification',
        content: '',
        title: 'New Notification',
        type: 'notification'
      },
    },
    success: {
      position: 'top-right',
      delay: 4000,
      autohide: true,
      stacking: true,
      data: {
        sound: 'notification',
        content: '',
        title: 'Success',
        type: 'success'
      },
    },
    error: {
      position: 'top-right',
      delay: 5000,
      autohide: true,
      stacking: true,
      data: {
        sound: 'error',
        content: '',
        type: 'error'
      }
    },
    navigatorerror: {
      position: 'bottom-center',
      delay: 5000,
      autohide: true,
      stacking: true,
      data: {
        sound: '',
        content: '',
        type: 'error'
      },
    }
  }
};

