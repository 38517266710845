<div class="container text-center">
  <div #iconWrapper class="mb-3">
    <i
      [@tadaEnter]
      class="fas fas fa-exclamation fa-2x bg-danger bg-gradient text-light"
    ></i>
  </div>
  <ng-container *ngIf="error.includes('authenticate');else displayError">
    <h1>Authentication Required</h1>
    <h5>You need to login before you can access this.</h5>
    <div (click)="login()" class="btn btn-primary btn-rounded block btn-block">Login / Register</div>

  </ng-container>
  <ng-template #displayError>
    <h1>We're Sorry!</h1>
    <h5>We ran into a problem while processing your request.</h5>
    <h6 *ngIf="error && error.toLowerCase() !== 'internal'">{{ error }}</h6>
  </ng-template>
</div>
