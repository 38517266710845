import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import Intercom from '@intercom/messenger-js-sdk';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  hmacUserHash
  constructor(private userService: UserService) {}

  public triggerInitialization(hmacUserHash: string): void {
    this.hmacUserHash= hmacUserHash
    this.userService.userChanged.subscribe((user: any) => {
        this.initializeIntercom(user);
    });
  }

  public initializeIntercom(user: any): void {
    if (!user) {
      console.warn('User data is not available for Intercom initialization.');
      Intercom({
        app_id: environment.intercomId,
        background_color: '#4f4f4f',
        action_color: '#0a3a8c',
      });
    } else {
      console.log(user)
      Intercom({
        app_id: environment.intercomId,
        ...(user.id && { user_id: user.id }),
        ...(user.name && { name: user.name }),
        ...(this.hmacUserHash && { user_hash: this.hmacUserHash }),
        ...(user.email && { email: user.email }),
        ...(user.metadata?.createdAt && { created_at: user.metadata.createdAt }),
        ...( user.photoURL && { 
          avatar: {
            type: 'avatar',
            image_url: user.photoURL,
          }
         }),
        background_color: '#4f4f4f',
        action_color: '#0a3a8c',
      });
    }
  }

}
