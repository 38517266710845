import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DateTime, Settings } from 'luxon';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Community } from 'src/app/interfaces/community';
import { fadeInEnterAnimation } from 'mdb-angular-ui-kit/animations';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { BackendService } from 'src/app/services/backend.service';
import { MdbNotificationConfig, MdbNotificationService } from 'mdb-angular-ui-kit/notification';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/user.service';
import { JoinedCommunitiesService } from 'src/app/services/joined-communities.service';
import { Subscription } from 'rxjs';
import { CreateEventComponent } from 'src/app/components/modals/create-event/create-event.component';
import { EditEventComponent } from 'src/app/components/modals/edit-event/edit-event.component';
import { ToastComponent } from 'src/app/components/shared/toast/toast.component';
import { PaymentDetailsComponent } from 'src/app/components/modals/payment-details/payment-details.component';

@Component({
  selector: 'app-community-manage-events',
  templateUrl: './events.component.html',
  styleUrl: './events.component.scss'
})
export class CommunityEventsComponent implements OnInit {
  eventLimit: number = 0
  loggedIn: boolean = false
  currentDate: Date = new Date()
  currentView: string = 'eventList'
  purchaseType: string = 'purchase'
  postDetails: any
  displaySelectDropdown: boolean = false
  venueDetails: any
  modalRef: MdbModalRef<CreateEventComponent> | null = null;
  editEventModalRef: MdbModalRef<EditEventComponent> | null = null;
  seatmapBackgroundImg: string = ''
  @Input() communityDetails: Community
  @Input() id
  @Input() initialLoad
  seriesEvents = []
  selectedSeats = []
  seatData = []
  events: any[] = []
  loading: boolean = true
  loadingEvent: boolean = false
  isLoadingSeatData: boolean = true
  startDate: string = DateTime.local().endOf('day').minus({ 'months': 24 }).toFormat("MM/dd/yyyy");
  endDate: string = DateTime.local().endOf('day').toFormat("MM/dd/yyyy");
  toastOptionsError = environment.toastOptions.error as MdbNotificationConfig<{}>;
  paymentDetailsModalRef: MdbModalRef<PaymentDetailsComponent>;

  purchaseLimit: number = 10
  limit: number = 5
  error
  errorEvent
  orderDetails
  private communitiesSubscription: Subscription;
  isUserCommunityMember: boolean = false

  constructor(
    private modalService: MdbModalService,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private location: Location,
    private backendService: BackendService,
    private joinedCommunitiesService: JoinedCommunitiesService,
    private toastService: MdbNotificationService
  ) {
    Settings.defaultZone = "America/New_York";
  }

  ngOnInit(): void {
    this.getEvents()
  }

  ngOnChanges() {
    this.route.queryParams
      .subscribe(params => {
        const eventId = params.id
        if (eventId) {
          this.getEvent(eventId)
          this.location.replaceState('/communities/' + this.communityDetails.id + '/manage/events?id=' + eventId)
        }
      })
  }

  getEvents() {
    this.loading = true
    this.backendService.getCommunityEvents(this.communityDetails.id, {
      startDate: (new Date(DateTime.fromFormat(this.startDate, "MM/dd/yyyy").toJSDate()).getTime() / 1000),
      endDate: (new Date(DateTime.fromFormat(this.endDate, "MM/dd/yyyy").endOf('day').toJSDate()).getTime() / 1000),
      limit: this.limit
    }).subscribe({
      next: async (res) => {
        this.events = res
        this.seriesEvents = res
        this.loading = false
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
        return
      },
    });
  }


  getEvent(eventId) {
    this.currentView = 'eventView'
    this.loadingEvent = true
    this.backendService.getCommunityManageEvent(this.communityDetails.id, eventId).subscribe({
      next: async (res) => {
        console.log('fetching event details')
        console.log(res)
        this.eventLimit = res.seatmap.length
        res.seatmap.push({
          "type": "Stage",
          "x": -12.5,
          "y": 3
        })
        this.seatData = res.seatmap
        this.venueDetails = res.venueDetails
        this.postDetails = res
        this.loadingEvent = false
        this.location.replaceState('/communities/' + this.communityDetails.id + '/manage/events?id=' + eventId)
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
        return
      },
    });
  }


  viewSeatmap(purchaseType: string) {
    this.selectedSeats = []
    this.currentView = 'seatMap'
    this.purchaseType = purchaseType
  }

  errorToast(message: string) {
    if (String(message).includes('Firebase')) {
      message = String(message).replace('Firebase: ', '')
      message = String(message).replace(/Firebase/g, '')
    }
    this.toastOptionsError.data['content'] = message;
    this.toastService.open(ToastComponent, this.toastOptionsError)
  }

  createEvent() {
    this.modalRef = this.modalService.open(CreateEventComponent, {
      modalClass: 'modal-dialog-scrollable modal-xl',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails
      }
    })
    this.modalRef.onClose.subscribe((data: any) => {
      if (data) {
        this.getEvents()
      }
    })
  }


  selectSeat(seatData) {
    console.log(seatData)
    this.paymentDetailsModalRef = this.modalService.open(PaymentDetailsComponent, {
      containerClass: 'top',
      modalClass: 'modal-dialog-scrollable modal-lg',
      ignoreBackdropClick: true,
      data: {
        paymentId: seatData.order,
        communityId: this.communityDetails.id
      }
    })
  }

  editEvent() {
    this.editEventModalRef = this.modalService.open(EditEventComponent, {
      modalClass: 'modal-dialog-scrollable modal-lg',
      ignoreBackdropClick: true,
      data: {
        communityDetails: this.communityDetails,
        eventDetails: this.postDetails
      }
    })
    this.editEventModalRef.onClose.subscribe((data: any) => {
      if (data === 'created') {
        this.getEvent(this.postDetails.id)
      }
    })
  }


  viewDetails(item: any) {
    this.errorEvent = null
    this.loadingEvent = false
    this.currentView = 'eventView'
    this.location.replaceState('/communities/' + this.communityDetails.id + '/manage/events?id=' + item.id)
    this.getEvent(item.id)
  }


  returnToList() {
    this.currentView = 'eventList'
    this.location.replaceState('/communities/' + this.communityDetails.id + '/manage/events')
  }

  enableTicketPurchase(salesStart: string | null): boolean {
    if (!salesStart) {
      return false; // No salesStart means tickets can be bought
    }
    if (this.isUserCommunityMember) {
      return false; // Allow community members to purchase tickets regardless of salesStart
    } else {
      return new Date(salesStart) >= this.currentDate;
    }
  }

}
