import { Component, OnInit } from '@angular/core';
import { ThemeToggleService } from 'src/app/services/theme-toggle.service';

@Component({
  selector: 'app-customer-view',
  templateUrl: './customer-view.component.html',
  styleUrls: ['./customer-view.component.scss'] // Fixed typo: 'styleUrl' to 'styleUrls'
})
export class CustomerViewComponent implements OnInit {

  constructor(
    private themeToggleService: ThemeToggleService
  ) {}

  ngOnInit() {
    this.themeToggleService.enableWakeLock()
  }
  
}
