<footer class="text-center text-lg-start text-muted" style="background-color: var(--footer)">
  <!-- <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom"> -->
  <section class="d-flex justify-content-center justify-content-lg-between border-bottom">
    <div class="me-5 d-none d-lg-block">
    </div>
    <div>
      <!-- <a href="" class="me-4 text-reset">
        <i class="fab fa-facebook-f"></i>
      </a>
      <a href="" class="me-4 text-reset">
        <i class="fab fa-twitter"></i>
      </a>
      <a href="" class="me-4 text-reset">
        <i class="fab fa-instagram"></i>
      </a> -->
    </div>
  </section>
  <section class="">
    <div class="container text-center text-md-start mt-5">
      <div class="row mt-3">
        <!-- First card that will be hidden on mobile devices -->
        <div class="col-md-6 col-lg-8 col-xl-6 mx-auto mb-8 d-none d-md-block">
          <h6 class="text-uppercase fw-bold mb-4">
            Event Odyssey
          </h6>
          <p>
            Experience the magic of school performances
            anytime, anywhere. Join now
            to become an approved member
            and unlock the best of school fine arts performances. </p>
        </div>
        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
          <h6 class="text-uppercase fw-bold mb-4">
            Useful links
          </h6>
          <p>
            <a href="#!" class="text-reset hoverUnderline" routerLink="/support">Support</a>
          </p>
          <p>
            <a href="#!" class="text-reset hoverUnderline" routerLink="/pricing">Pricing</a>
          </p>
          <p>
            <a href="#!" class="text-reset hoverUnderline" routerLink="/videos">My Videos</a>
          </p>
          <p>
            <a href="#!" class="text-reset hoverUnderline" routerLink="/purchases">Purchase History</a>
          </p>
        </div>
        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
          <h6 class="text-uppercase fw-bold mb-4">
            Legal
          </h6>
          <p>
            <a href="#!" class="text-reset hoverUnderline" routerLink="/privacy-policy">Privacy Policy</a>
          </p>
          <p>
            <a href="#!" class="text-reset hoverUnderline" routerLink="/terms-of-service">Terms of Service</a>
          </p>
        </div>
      </div>
    </div>
  </section>
  <div class="text-center p-4" style="background-color: var(--footer-darker)">
    © 2024 Copyright
    <a class="text-reset fw-bold">Event Odyssey Inc.</a>
  </div>
</footer>
