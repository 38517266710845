import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ActivatedRoute } from '@angular/router';
import { MdbTabsComponent } from 'mdb-angular-ui-kit/tabs';
import { MdbTabChange } from 'mdb-angular-ui-kit/tabs/tabs.component';
import { Community } from 'src/app/interfaces/community';
import { Location } from '@angular/common';

@Component({
  selector: 'app-community-manage-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss']
})
export class SalesOverviewComponent implements OnInit {
  @Input() communityDetails: any
  @Input() environmentName: string
  @ViewChild(MdbTabsComponent) tabs: MdbTabsComponent
  firstTabChange: boolean = true
  @Input() isVertical: boolean = true
  stripeAccountDetails: any
  constructor(
    private route: ActivatedRoute,
    private functions: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.setActiveSection()
  }


  async setActiveSection() {
    const section = this.route.snapshot.params['settingsSection']
    if (this.tabs) {
      let sections = ['overview', 'payouts', 'transactions', 'sales-tax', 'card-readers'];
      if(this.environmentName==='production') {
        sections = ['overview', 'payouts', 'transactions', 'sales-tax', 'card-readers'];
      }
      let tabIndex = sections.indexOf(section);
      if (tabIndex !== -1) {
        this.tabs.setActiveTab(tabIndex);
      }
    } else {
      // Keep retrying until tabs available
      setTimeout(() => {
        this.setActiveSection()
      }, 10);
    }

  }


  onTabChange(event: MdbTabChange): void {
    if (this.firstTabChange) { this.firstTabChange = false } else {
      const tabName = event.tab.title.toLowerCase()
      this.location.replaceState('/communities/' + this.communityDetails.id + '/manage/billing/' + tabName.replace(/[\s\/]+/g, "-"))
    }
  }


  outputStripeDetails($event) {
    this.stripeAccountDetails = $event
  }

}
