import { Component } from '@angular/core';

@Component({
  selector: 'app-cashier-view',
  templateUrl: './cashier-view.component.html',
  styleUrl: './cashier-view.component.scss'
})
export class CashierViewComponent {

}
