<ng-container *ngIf="loading; else notloading">
  <app-loading></app-loading>
</ng-container>
<ng-template #notloading>
  <form [formGroup]="productForm">
    <div class="card mb-3">
      <div class="card-body">
        <div *ngFor="let seat of selectedAddons; let i = index" class="mb-2">
          <div class="row">
            <div class="col-12">
              <h6>{{seat.name}}</h6>
              <p [innerHTML]="seat.description"></p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 seatInfo-text">
              <div>{{seat.label}}</div>
            </div>
            <div class="col-sm-12 col-md-8 col-lg-4 col-xl-4 d-flex align-items-center">
              <div class="input-group d-flex w-100">
                <button type="button" class="btn btn-primary" style="width: 50px;" (click)="decreaseAmount(seat.id)">-</button>
                <mdb-form-control [formGroupName]="seat.id" class="flex-grow-1 mx-2">
                  <input 
                    mdbInput 
                    formControlName="amount" 
                    type="number" 
                    class="form-control text-center" 
                    [min]="0" 
                    [max]="maxLimit" 
                    (change)="validateAmount(seat.id)" 
                    (input)="validateAmount(seat.id)" 
                    readonly>
                </mdb-form-control>
                <button type="button" class="btn btn-primary" style="width: 50px;" (click)="increaseAmount(seat.id)">+</button>
                <br>
                <div *ngIf="(productForm.controls[seat.id].value.amount)>=maxLimit" class="w-100 text-center small">
                  Max Limit {{maxLimit}}
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 price-text">
              <ng-container *ngIf="productForm.controls[seat.id].value.amount > 0; else noneSelected">
                {{((productForm.controls[seat.id].value.price * productForm.controls[seat.id].value.amount) / 100) | currency}}
              </ng-container>
              <ng-template #noneSelected>
                ----
              </ng-template>
            </div>
          </div>
          <hr class="hr" />
          <div class="text-end" *ngIf="((productForm.controls[seat.id].value.amount)&&(productForm.controls[seat.id].value.amount>0))">
            <span class="badge rounded-pill badge-light mr-2">
              {{productForm.controls[seat.id].value.amount}}x - {{productForm.controls[seat.id].value.name}}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col price-text">
            Addons Subtotal: <b>{{(totalPrice / 100) | currency}}</b>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
