<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">{{title}}</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
    <div class="container">
      <div *ngIf="description" class="row mb-3">
        <div class="text-center">
          {{description}}
        </div>
      </div>
      <div class="row">
        <div class="d-flex border mb-3 btn" (click)="socialLogin('google')" title="Continue with Google"  role="button">
          <div class="flex-shrink-1">
            <a class="btn btn-primary btn-floating mb-1" (click)="socialLogin('google')" style="background-color: #dd4b39; box-shadow: 0 4px 9px -4px #852d22" role="button"><i class="fab fa-google"></i></a>
          </div>
          <div class="align-self-start mt-1 ms-3 loginButton">Continue with Google</div>
        </div>
        <div class="d-flex border mb-3 btn" (click)="socialLogin('apple')" title="Continue with Apple"  role="button">
          <div class="flex-shrink-1">
            <a class="btn btn-primary btn-floating mb-1" (click)="socialLogin('apple')" style="background-color: #323232; box-shadow: 0 4px 9px -4px #1e1e1e" role="button"><i class="fab fa-apple"></i></a>
          </div>
          <div class="align-self-start mt-1 ms-3 loginButton">Continue with Apple</div>
        </div>
        <div class="d-flex border mb-3 btn" (click)="socialLogin('microsoft')" title="Continue with Microsoft" role="button">
          <div class="flex-shrink-1">
            <a class="btn btn-primary btn-floating mb-1" style="background-color: #0078d4; box-shadow: 0 4px 9px -4px #00487f" role="button"><i class="fab fa-windows"></i></a>
          </div>
          <div class="align-self-start mt-1 ms-3 loginButton">Continue with Microsoft</div>
        </div>
        <div class="d-flex border mb-3 btn" (click)="socialLogin('facebook')" title="Continue with Facebook" role="button">
          <div class="flex-shrink-1">
            <a class="btn btn-primary btn-floating mb-1" style="background-color: #3b5998; box-shadow: 0 4px 9px -4px #23355b" role="button"><i class="fab fa-facebook-f"></i></a>
          </div>
          <div class="align-self-start mt-1 ms-3 loginButton">Continue with Facebook</div>
        </div>
        <div class="d-flex border mb-3 btn" (click)="socialLogin('yahoo')" title="Continue with Yahoo" role="button">
          <div class="flex-shrink-1">
            <a class="btn btn-primary btn-floating mb-1" style="background-color: #400090; box-shadow: 0 4px 9px -4px #260056" role="button"><i class="fab fa-yahoo"></i></a>
          </div>
          <div class="align-self-start mt-1 ms-3 loginButton">Continue with Yahoo</div>
        </div>
        <div class="d-flex border mb-2 btn" (click)="socialLogin('twitter')" title="Continue with Twitter" role="button">
          <div class="flex-shrink-1">
            <a class="btn btn-primary btn-floating mb-1" style="background-color: #55acee; box-shadow: 0 4px 9px -4px #33678f" role="button"><i class="fab fa-twitter"></i></a>
          </div>
          <div class="align-self-start mt-1 ms-3 loginButton">Continue with Twitter</div>
        </div>
      </div>
    </div>
</div>
