<ng-container *ngIf="!loading; else displayLoading">
  <div class="datatable">
    <div class="d-flex justify-content-between" style="margin-bottom: 20px">
      <div>
        <div class="input-group">
          <input *ngIf="cardReaders.length>0" type="text" class="form-control form-control-lg"
            placeholder="Filter readers" (keyup)="search($event)" #searchInput size=25 />
        </div>
      </div>
      <div>
        <div mdbDropdown #viewDrop class="dropdown" [animation]="false">
          <button class="btn btn-grey btn-rounded dropdown-toggle me-1" type="button" id="dropdownMenuButton"
            aria-expanded="false" mdbDropdownToggle>
            <span><i class="fas fa-cog me-2"></i>Actions</span>
          </button>
          <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
            <li (click)="launchPOS()"><a class="dropdown-item pointer"><i class="fas fa-cash-register me-2"></i>Launch POS</a></li>
            <li (click)="createCardReader()"><a class="dropdown-item pointer"><i class="fas fa-plus me-2"></i>Add Card Reader</a></li>
            <li (click)="fetchCardReaders()"><a class="dropdown-item pointer"><i
                  class="fas fa-sync-alt me-2"></i>Refresh</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div *ngIf="cardReaders.length===0">
      <app-no-results message="No registered card readers found"></app-no-results>
    </div>
    <div *ngIf="cardReaders.length>0">
      <table responsive class="table align-middle mb-0 bg-white"
        class="table datatable-table table-striped table-hover mt-3" style="margin-top: 0px !important;" mdbTable
        mdbTableSort #table="mdbTable" #sort="mdbTableSort" [style]="" [responsive]="true" [dataSource]="cardReaders"
        [pagination]="pagination" [sort]="sort" [filterFn]="filterTable">
        <ng-container *ngIf="!(pagination._total===0&&searchText); else noResultsFound">
          <thead class="bg-light">
            <tr>
              <th [mdbTableSortHeader]="'label'">Device</th>
              <th [mdbTableSortHeader]="'serial_number'">Serial</th>
              <th></th>
            </tr>
          </thead>
        </ng-container>
        <ng-template #noResultsFound>
          <div class="mb-5"></div>
          <app-no-results [message]="'No card readers found matching: ' + searchText"></app-no-results>
          <div class="mb-5"></div>
        </ng-template>
        <tbody>
          <tr *ngFor="let item of table.data; let i = index">
            <td>
              <div class="d-flex align-items-center">
                <img *ngIf="item.device_type=='stripe_s700'" src="./assets/device-icons/stripe-s700.png" alt="Card Reader" style="width: 45px; height: 45px;" />
                <img *ngIf="item.device_type=='bbpos_wisepos_e'" src="./assets/device-icons/WisePOS-E-Thumb.png" alt="Card Reader" style="width: 45px; height: 45px;" />

                <div class="ms-3">
                  <p class="fw-bold mb-1">{{item.label}}</p>
                  <p class="text-muted mb-0">Device:: {{item.device_type}}</p>
                  <p class="text-muted mb-0">Firmware: {{item.device_sw_version}}</p>
                  <p class="text-muted mb-0">IP: {{item.ip_address}}</p>

                </div>
              </div>
            </td>
            <td style="width: 150px">
              <p class="fw-normal mb-1">{{item.serial_number}}</p>
            </td>
            <td style="width: 50px" class="text-end">
              <span style="margin-left: 10px;" mdbDropdown class="dropdown dropend">
                <span mdbRipple style="width: 36px; height: 36px; border-radius: 100%;"
                  class="dropdown-toggle hidden-arrow tableDropdown text-center " role="button" mdbDropdownToggle aria-expanded="false">
                  <i style="height: 60px; margin-left: 0px; margin-top: 17px"
                    class="fas fa-ellipsis-v pointer text-dark fa-lg"></i>
                </span>
                <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                  <li>
                    <a class="dropdown-item pointer">Edit Reader</a>
                    <a (click)="deleteReader(item)" class="dropdown-item pointer">Delete Reader</a>
                  </li>
                </ul>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <mdb-table-pagination #pagination [entries]="10" rowsPerPageText="Results"></mdb-table-pagination>
    </div>
  </div>
</ng-container>
<ng-template #displayLoading>
  <ng-container *ngIf="!error; else displayError">
    <app-loading></app-loading>
  </ng-container>
  <ng-template #displayError>
    <app-https-error [error]="error"></app-https-error>
  </ng-template>
</ng-template>
