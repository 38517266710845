import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  checkoutForm: FormGroup
  @Input() paymentMethod = null
  @Input() paymentErrorType = 'warning'
  @Input() paymentError = null
  @Input() additionalInfo: any
  @Input() productDetails: any
  @Input() eventDetails: any
  @Input() ticketDetails: any
  @Input() addonsDetails: any
  @Input() priceDetails: any
  @Input() itemDetails: any
  @Input() type: string = 'video'
  @Output() isValid = new EventEmitter<any>();
  @Output() output = new EventEmitter<any>();
  @Output() previous = new EventEmitter<any>();
  @Output() setStepperToPaymentMethod = new EventEmitter<any>();



  previousPaymentMethod = undefined
  previousPriceDetails = undefined


  constructor(
    private analytics: AngularFireAnalytics
  ) { }

  ngOnInit(): void {
    console.log('laddon details')
    console.log(this.addonsDetails)
    this.checkoutForm = new FormGroup({
      budget: new FormControl('', [Validators.required]),
    });
    this.onFormChanges()
  }

  ngOnChanges() {
    if (JSON.stringify(this.paymentMethod) !== JSON.stringify(this.previousPaymentMethod)) {
      this.previousPaymentMethod = this.paymentMethod
      if (this.paymentMethod.paymentMethodId) { this.logAddPaymentInfoAnalytics() }
    }
    // Price & Product Details update together
    if (JSON.stringify(this.priceDetails) !== JSON.stringify(this.previousPriceDetails)) {
      this.previousPriceDetails = this.priceDetails
      if (this.priceDetails.unit_amount) { this.logAddToCartAnalytics() }
    }
  }

  onFormChanges() {
    this.checkoutForm.valueChanges.subscribe(val => {
      this.isValid.emit(this.checkoutForm.valid);
      this.output.emit((JSON.parse(JSON.stringify(this.checkoutForm.value))));
    })
  }

  emitSetStepperToPaymentMethod() {
    this.setStepperToPaymentMethod.emit()
  }

  emitPrevious() {
    this.previous.emit()
  }

  logAddToCartAnalytics() {
    switch (this.type) {
      case 'video':
        this.analytics.logEvent('add_to_cart', {
          value: (this.priceDetails.amount / 100),
          currency: this.priceDetails.currency,
          items: [{
            item_id: this.priceDetails.id,
            item_name: this.productDetails.name + '(' + this.itemDetails.name + ')',
            affiliation: this.itemDetails.communityDetails.name
          }]
        })
      case 'tickets':
        break;
    }

  }

  logAddPaymentInfoAnalytics() {
    switch (this.type) {
      case 'video':
        this.analytics.logEvent('add_payment_info', {
          value: (this.priceDetails.amount / 100),
          currency: this.priceDetails.currency,
          payment_type: 'Credit Card',
          items: [{
            item_id: this.priceDetails.id,
            item_name: this.productDetails.name + '(' + this.itemDetails.name + ')',
            affiliation: this.itemDetails.communityDetails.name
          }]
        })
      case 'tickets':
        break;
    }
  }


}
