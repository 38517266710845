<ng-container  *ngIf="communityDetails.blocked!==true; else displayBlocked">
  <ng-container *ngIf="loggedIn ;else notLoggedIn">
    <div class="row mt-3">
      <div class="row">
        <h4>
          You aren't a member of this community yet!
        </h4>
        <p>Before you can view events and videos from this community you must be registered. Look below for methods to get connected!</p>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12 col-lg-12 col-xl-12 mx-auto mb-12">
        <div class="row">
          <div class="col-md-6 col-lg-6 col-xl-6">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Enrollment code</h5>
                <p class="card-text">Enter enrollment code to instally join a community.</p>
                <button (click)="joinWithCode()" type="button" class="btn btn-primary btn-rounded">Join with Enrollment Code</button>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xl-6">
            <div class="card">
              <div class="card-body">
                <div *ngIf="communityDetails.requests.length!==0">
                  <div class="text-center mb-2">
                    <div #iconWrapper>
                      <i
                        *ngIf="iconVisible"
                        [@tadaEnter]
                        class="fas fas fa-check fa-2x bg-success bg-gradient text-light"
                      ></i>
                    </div>
                  </div>
                  <div class="text-center">
                    <h5>Request Received</h5>
                    <p>We'll send you an email once {{communityDetails.name}} has responded to your request.</p>
                  </div>
                </div>
                <div *ngIf="communityDetails.requests.length===0">
                  <h5 class="card-title">Request Access</h5>
                  <p class="card-text">Submit a form to request access to the community</p>
                  <button (click)="requestAccess()" type="button" class="btn btn-primary btn-rounded">Request Access</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #notLoggedIn>
    <h1>Authentication Required</h1>
    <h5>You need to login before you can access this.</h5>
    <div (click)="login()" class="btn btn-primary btn-rounded block btn-block">Login / Register</div>
    
  </ng-template>
</ng-container>
<ng-template #displayBlocked>
  <div class="row mt-3">
    <div class="row">
      <app-not-found></app-not-found>
    </div>
  </div>
</ng-template>
