import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-ticket-addons',
  templateUrl: './ticket-addons.component.html',
  styleUrls: ['./ticket-addons.component.scss']
})
export class TicketAddonsComponent implements OnInit {
  productForm: FormGroup;
  @Input() selectedAddons: any[];
  @Output() isValid = new EventEmitter<any>();
  @Output() output = new EventEmitter<any>();
  totalPrice: number = 0;
  @Input() maxLimit: number = 0;
  loading: boolean = false;

  constructor(
    private functions: AngularFireFunctions,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.productForm = this.fb.group({});
  }

  ngOnInit(): void {
    this.selectedAddons.forEach((seat) => {
      const group = this.fb.group({
        name: [seat.name, Validators.required],
        price: [seat.price, Validators.required],
        amount: [0, [Validators.required, Validators.min(0), Validators.max(this.maxLimit)]]
      });
      this.productForm.addControl(seat.id, group);
    });
    this.onFormChanges();
  }

  onFormChanges() {
    this.updatePrices();
    this.isValid.emit(this.productForm.valid);
    this.productForm.valueChanges.subscribe(async (val) => {
      await this.updatePrices();
      this.isValid.emit(this.productForm.valid);
    });
  }

  async updatePrices() {
    let totalPrice = 0;
    let addonsOuput = []
    this.selectedAddons.forEach((seat) => {
      const seatFormGroup = this.productForm.get(seat.id);
      const amount = seatFormGroup.get('amount').value;
      const price = seatFormGroup.get('price').value;
      totalPrice += price * amount;
      addonsOuput.push({
        ...seat,
        amount: amount,
        price: price
      })
    });

    this.totalPrice = totalPrice;
    this.output.emit({ selectedAddons: addonsOuput, totalPrice: totalPrice });
  }

  increaseAmount(seatId: string) {
    const seatFormGroup = this.productForm.get(seatId);
    let currentAmount = seatFormGroup.get('amount').value;
    if (currentAmount < this.maxLimit) {
      seatFormGroup.get('amount').setValue(currentAmount + 1);
    }
  }

  decreaseAmount(seatId: string) {
    const seatFormGroup = this.productForm.get(seatId);
    let currentAmount = seatFormGroup.get('amount').value;
    if (currentAmount > 0) {
      seatFormGroup.get('amount').setValue(currentAmount - 1);
    }
  }

  validateAmount(seatId: string) {
    const seatFormGroup = this.productForm.get(seatId);
    let currentAmount = seatFormGroup.get('amount').value;

    // Ensure the value stays within bounds
    if (currentAmount < 0) {
      seatFormGroup.get('amount').setValue(0);
    } else if (currentAmount > this.maxLimit) {
      seatFormGroup.get('amount').setValue(this.maxLimit);
    }
  }

  
}
