import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { StripeService, StripePaymentElementComponent, StripeInstance, StripeFactoryService } from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementsOptions
} from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-add-guest-card',
  templateUrl: './add-guest-card.component.html',
  styleUrl: './add-guest-card.component.scss'
})
export class AddGuestCardComponent {
  stripe: StripeInstance;
  @Output('outputAddedCard') outputAddedCard: EventEmitter<any> = new EventEmitter();
  // Form
  @ViewChild(StripePaymentElementComponent)
  paymentElement: StripePaymentElementComponent;
  paymentError: string = ''
  paymentErrorType: string = 'warning'

  appearance = {
    theme: 'stripe',

    variables: {
      colorPrimary: '#0570de',
      colorBackground: '#ffffff',
      colorText: '#30313d',
      colorDanger: '#df1b41',
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      spacingUnit: '2px',
      borderRadius: '4px',
      // See all possible variables below
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
    fonts: [{
      family: 'Roboto',
      src: 'url(https://fonts.googleapis.com/css?family=Roboto:500&display=swap)',
      weight: '500',
    }],
    appearance: {
      theme: 'stripe',
      variables: {
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontWeightNormal: '500',
        borderRadius: '8px',
        colorPrimary: '#1266f1',
      }
    }
  }

  paying: boolean = false;
  loading: boolean = true
  constructor(
    private stripeService: StripeService,
    private backendService: BackendService,
    private stripeFactory: StripeFactoryService,) { }

  ngOnInit(): void {
    this.setStripeInstance()
  }

  setStripeInstance() {
    this.stripe = this.stripeFactory.create(environment.stripe);
    this.backendService.setupGuestIntent().subscribe({
      next: async (res) => {
        console.log(res)
        this.loading = false
        this.elementsOptions.clientSecret = res.client_secret
      },
      error: (err) => {
        console.warn(err)
        return
      },
    });


  }

  addCard() {
    this.clearPaymentError()
    this.paying = true;
    this.stripe.confirmSetup({
      elements: this.paymentElement.elements,
      redirect: 'if_required'
    }).subscribe(result => {
      if (result.error) { this.handlePaymentError(result.error.message, 'danger') }
      else {
        switch (result.setupIntent.status) {
          case 'succeeded':
          case 'processing':
            console.log(result)
            this.paying = false
            this.outputAddedCard.emit(result.setupIntent)
            break;
          case 'requires_action':
            this.handlePaymentError('Your payment method requires additional information to process the payment successfully.')

            break;
        }
      }
    });
  }

  handlePaymentError(message: string, type?: string) {
    if (!type) { type = 'warning' }
    if (String(message).includes('Firebase')) {
      message = String(message).replace('Firebase: ', '')
      message = String(message).replace(/Firebase/g, '')
    }
    if (String(message).includes('declined')) { type = 'danger' }
    this.paying = false
    this.paymentErrorType = type
    this.paymentError = message
  }

  clearPaymentError() {
    this.paymentError = ''
    this.paymentErrorType = 'warning'
  }




}
