<div *ngIf="paymentMethod.paymentMethod" class="row">
  <div class="col-md-0 col-lg-6 col-xl-6">

    <div *ngIf="additionalInfo.email">
      <span class="small">Email for Receipt:</span><br>
      {{additionalInfo.email}}
    </div>
    <!-- <div *ngIf="type==='tickets'&&eventDetails" class="card border border-gray">
      <div class="card-body">
      <h5 class="card-title">{{eventDetails.title}}</h5>
      <p [innerHTML]="eventDetails.body| ngXtruncate:300"></p>
      <div class="card-text">
        <div>{{eventDetails.startTime | date:'long'}}</div>
        <div>{{eventDetails.location.address}}</div>
      </div>
      </div>
    </div> -->
  </div>
  <div class="col-md-12 col-lg-6 col-xl-6">
    <div class="card border border-grey">
      <div class="card-body">
        <h5 class="card-title">
          <app-card-detail [cardDetails]="paymentMethod.paymentMethod"></app-card-detail>
        </h5>
        <p class="card-text" *ngIf="paymentError">
          <app-notice [content]="paymentError" [type]="paymentErrorType"></app-notice><br><br>
        </p>
        <p class="card-text">
          <span (click)="emitSetStepperToPaymentMethod()" class="hoverUnderline pointer">Change</span>
        </p>
      </div>
    </div>
  </div>
</div>

<br><br>
<div *ngIf="type==='tickets'" class="row">
  <div class="card-body">
    <div class="card-text">
      <div *ngFor="let seat of ticketDetails.seats; let i = index" class="mb-2">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 seatInfo-text">
            <h6>Section: {{seat.section}}</h6>
            <h6>Row: {{seat.row}}</h6>
            <h6>Seat: {{seat.seat}}</h6>
          </div>

          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 price-text">
            {{seat.type}}<br>
            {{(seat.price / 100) | currency}}
          </div>
        </div>
      </div>
      <div *ngIf="addonsDetails.selectedAddons.length>0">
        <div *ngFor="let selectedAddon of addonsDetails.selectedAddons; let i = index" class="mb-2">
          <div *ngIf="selectedAddon.amount>0">
            <hr *ngIf="i===0" class="hr" />
            <div class="row" *ngIf="selectedAddon.amount>0">
              <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 seatInfo-text">
                <h6>{{selectedAddon.name}}</h6>
              </div>
    
              <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 price-text">
                <div>{{selectedAddon.amount}}x {{((selectedAddon.price) / 100) | currency}}</div>
                <b>{{((selectedAddon.price*selectedAddon.amount) / 100) | currency}}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr  class="hr" />
    </div>
    <div class="row">
      <div class="text-end">
        <span *ngFor="let ticketType of ticketDetails.ticketTypeCounts" class="badge rounded-pill badge-light  mr-2">
          {{ ticketType.count }}x - {{ ticketType.type }}{{ ticketType.count > 1 ? 's' : '' }}
        </span>
        <span *ngFor="let selectedAddon of addonsDetails.selectedAddons" class="badge rounded-pill badge-light  mr-2">
          <span *ngIf="selectedAddon.amount>0">{{ selectedAddon.amount }}x - {{ selectedAddon.name }}</span>
        </span>
      </div>
      <div class="col-md-9 col-lg-9 col-xl-9">
        Subtotal
      </div>
      <div class="col-md-3 col-lg-3 col-xl-3 text-end">
        <b>{{((ticketDetails.totalPrice+addonsDetails.totalPrice)/100)| currency}}</b>
      </div>
    </div>
    <div class="row">
      <div class="col-md-9 col-lg-9 col-xl-9">
        Tax
      </div>
      <div class="col-md-3 col-lg-3 col-xl-3 text-end">
        {{ 0 | currency}}
      </div>
    </div>
    <div class="row">
      <div class="col-md-9 col-lg-9 col-xl-9">
        <b>Total</b>
      </div>
      <div class="col-md-3 col-lg-3 col-xl-3 text-end">
        <b>{{((ticketDetails.totalPrice+addonsDetails.totalPrice)/100)| currency}}</b>
      </div>
    </div>
  </div>
</div>

<div *ngIf="type==='video'" class="row">
  <div class="card-body">
    <h5 class="card-title">{{productDetails.name}}</h5>
    <div class="card-text">
      <div class="small" [innerHTML]="productDetails.description"></div>
      <span *ngIf="productDetails.ignoredVideoIds?.includes(itemDetails.id)" class="badge badge-warning ml-2"><span class="">{{itemDetails.name}} not included</span> </span>
    </div>
    <div class="row">
      <div class="col-md-9 col-lg-9 col-xl-9">
        Subtotal
      </div>
      <div class="col-md-3 col-lg-3 col-xl-3 text-end">
        {{(priceDetails.unit_amount/100)| currency}}
      </div>
    </div>
    <div class="row">
      <div class="col-md-9 col-lg-9 col-xl-9">
        {{priceDetails.taxName || 'Tax'}}
      </div>
      <div class="col-md-3 col-lg-3 col-xl-3 text-end">
        {{(priceDetails.tax/100)| currency}}
      </div>
    </div>
    <div class="row">
      <div class="col-md-9 col-lg-9 col-xl-9">
        <b>Total</b>
      </div>
      <div class="col-md-3 col-lg-3 col-xl-3 text-end">
        <b>{{((priceDetails.unit_amount+priceDetails.tax)/100)| currency}}</b>
      </div>
    </div>
  </div>
</div>
