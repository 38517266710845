import { Component, Input, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { saveAs } from 'file-saver';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {
  @Input() videoDetails
  link: string = ''
  linkedOpen: boolean = false
  preCheckPassed: boolean = true
  isLoadingPrecheck: boolean = true
  isSaving: boolean = false
  totalEntitledDownloads: number = 0
  remainingDownloads: number = 0
  error
  constructor(
    public modalRef: MdbModalRef<DownloadComponent>,
    private functions: AngularFireFunctions,
    private backendService: BackendService
  ) { }

  ngOnInit(): void {
    this.fetchCreateVideoPrecheck()
  }


  fetchCreateVideoPrecheck() {
    this.isLoadingPrecheck = true
    this.backendService.getVideoDownloadEntitlements(this.videoDetails.id).subscribe({
      next: (res) => {
        console.log(res)
        this.totalEntitledDownloads = res.totalDownloads
        this.remainingDownloads = res.remainingDownloads
        this.preCheckPassed = true
        this.isLoadingPrecheck = false
        return
      },
      error: (err) => {
        console.warn(err)
        this.error = err.message
        return
      },
    });
  }

  submit() {
    this.isSaving = true
    this.backendService.getVideoDownloadLink(this.videoDetails.id).subscribe({
      next: (res) => {
        this.openLink(res.url)
        this.isSaving = false
        return
      },
      error: (err) => {
        this.error = err.message
        this.preCheckPassed = false
        console.warn(err)
        return
      },
    });
  }

  openLink(url: string) {
    this.linkedOpen = true
    this.link = url
    // Open the link directly, letting the browser handle the download.
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', ''); // Forces the download behavior

    link.target = '_blank'; // Optional: open in a new tab
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

}
