import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ConfirmActionComponent } from '../confirm-action/confirm-action.component';
import { CustomerBillingInfoComponent } from '../customer-billing-info/customer-billing-info.component';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss']
})
export class PaymentDetailsComponent implements OnInit {
  confirmDeleteModalRef: MdbModalRef<ConfirmActionComponent>;
  customerBillingInfoModalRef: MdbModalRef<CustomerBillingInfoComponent> | null = null;

  @Input() paymentDetails
  customerDetails: any
  chargeDetails: any
  lineItems: any
  calculatedCharges: any
  communityDetails: any
  @Input() paymentId
  @Input() communityId
  loading: boolean = true
  error
  constructor(
    public modalRef: MdbModalRef<PaymentDetailsComponent>,
    private modalService: MdbModalService,
    private currencyPipe: CurrencyPipe,
    private backendService: BackendService,
    private functions: AngularFireFunctions
  ) { }

  ngOnInit(): void {
      this.fetchPaymentDetails()
  }


  fetchPaymentDetails() {
    console.log('fetching payment details')
    this.loading = true
    this.backendService.getCommunityTransaction(this.communityId, this.paymentId).subscribe({
      next: async (res) => {
        console.log('getting transaction')
        console.log(res)
        this.customerDetails = res.customerDetails
        this.chargeDetails = res.charge
        this.communityDetails = res.communityDetails
        this.lineItems = res.lineItems
        this.calculatedCharges = res.calculatedCharges
        this.paymentDetails = res
        this.loading = false
      },
      error: (err) => {
        console.log('error happened')
        console.log(err)
        console.warn(err)
        console.log('error happened')
        this.error = err.message
        return
      },
    });
  }

  viewCustomer() {
    this.customerBillingInfoModalRef = this.modalService.open(CustomerBillingInfoComponent, {
      modalClass: 'modal-dialog-scrollable modal-lg',
      ignoreBackdropClick: true,
      data: {
        communityId: this.communityId,
        customerId: this.paymentDetails.userId
      }
    })
  }

  refund(item: any) {
    const formattedAmount = this.currencyPipe.transform((item.amount / 100), 'USD', 'symbol', '1.2-2');
    let content = `<div class="text-center">Are you sure you want to issue a refund of ${formattedAmount} to ${item.customer.name}?</p></div>`;
    this.confirmDeleteModalRef = this.modalService.open(ConfirmActionComponent, {
      containerClass: 'top',
      modalClass: 'modal-dialog-scrollable modal-central modal-md',
      ignoreBackdropClick: true,
      data: {
        title: 'Refund Transaction',
        content: content,
        confirmButton: 'Refund',
        confirmButtonPlural: 'Refunding',
        type: 'refund',
        miscData: {
          communityId: this.communityId,
          id: item.id
        }
      }
    })
    this.confirmDeleteModalRef.onClose.subscribe((message: any) => {
      if (message === 'refunded') {
        this.fetchPaymentDetails()
      }
    });
  }

}
