<ng-container *ngIf="!loading; else displaySkeleton">
    <ng-container [ngSwitch]="currentView">
        <div *ngSwitchCase="'eventList'" [@fadeInEnter]>
            <div style="margin-bottom: 20px">
                <div>
                    <div style="text-align:right;">
                        <div mdbDropdown #viewDrop class="dropdown" [animation]="false">
                            <button class="btn btn-grey btn-rounded dropdown-toggle me-1" type="button"
                                id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle>
                                <span><i class="fas fa-cog me-2"></i>Actions</span>
                            </button>
                            <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end"
                                aria-labelledby="dropdownMenuButton">
                                <li (click)="createEvent()"><a class="dropdown-item pointer"><i
                                            class="fas fa-plus me-2"></i>Create
                                        Event</a>
                                </li>
                                <li (click)="getEvents()"><a class="dropdown-item pointer"><i
                                            class="fas fa-sync-alt me-2"></i>Refresh</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div *ngFor="let item of events; let i = index" class="col-xs-12 col-md-6 col-lg-4 col-xl-4">
                    <div class="card mb-4">
                        <div class="card-body">
                            <h5 class="card-title">{{item.title}}</h5>
                            <p class="card-text" [innerHTML]="item.body | htmlTruncate:100"></p>
                            <div class="card-text">
                                <div class="d-flex align-items-center">
                                    <!-- <img referrerPolicy="no-referrer" [src]="item.editors[0].profilePic" alt=""
                    style="width: 40px; height: 40px;" class="rounded-circle" /> -->
                                    <div class="">
                                        <p class="lh-1 fw-bold mb-1">{{item.startTime | date:'fullDate'}}</p>
                                        <p class="lh-1 text-muted mb-0">
                                            {{item.startTime | date:'shortTime'}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <br class="noselect">
                            <p class="card-text">
                                <button (click)="viewDetails(item)" type="button"
                                    class="btn btn-primary btn-rounded">Details</button>
                            </p>
                        </div>
                    </div>
                </div>
                <div *ngIf="events.length===0" class="row">
                    <app-no-results [message]="'No events found for ' + communityDetails.name + '.'"></app-no-results>
                </div>
            </div>
        </div>
        <div *ngSwitchCase="'eventView'" [@fadeInEnter]>
            <div class="d-flex justify-content-between" style="margin-bottom: 20px">
                <div>
                    <div class="d-flex align-items-center">
                        <div>
                            <div class="d-flex align-items-center">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li (click)="returnToList()" class="breadcrumb-item pointer"><a>All Events</a>
                                        </li>
                                        <li *ngIf="!loadingEvent" class="breadcrumb-item active" aria-current="page">
                                            {{postDetails.title}} <span class="text-muted">({{postDetails.startTime | date:'fullDate'}} at {{postDetails.startTime | date:'shortTime'}})</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="d-flex justify-content-end align-items-center">
                        <div mdbDropdown #viewDrop class="dropdown" [animation]="false">
                            <button class="btn btn-grey btn-rounded dropdown-toggle me-1" type="button"
                                id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle>
                                <span><i class="fas fa-cog me-2"></i>Actions</span>
                            </button>
                            <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end"
                                aria-labelledby="dropdownMenuButton">
                                <li (click)="editEvent()"><a class="dropdown-item pointer"><i
                                            class="fas fa-edit me-2"></i>Edit Event</a>
                                </li>
                                <li (click)="getEvent(postDetails.id)"><a class="dropdown-item pointer"><i class="fas fa-sync-alt me-2"></i>Refresh</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="!loadingEvent; else displayEventLoading">
                <div class="text-center row mt-3 mb-5">
                    <div class="col-lg-3 col-md-6 mb-5 mb-md-0 position-relative p-4 rounded shadow-sm bg-white pt-3 pb-3">
                        <i class="fas fa-ticket fa-3x text-primary mb-4 d-block text-center"></i>
                        <h5 class="text-primary fw-bold text-center mb-3">{{postDetails.stats.total.totalTickets}}</h5>
                        <h6 class="fw-normal text-center mt-3">Issued Tickets</h6>
                        <div class="d-flex justify-content-between small mb-1">
                            <span>Assigned Seats</span>
                            <span>{{postDetails.stats.total.totalTickets - postDetails.stats.vip.totalTickets}}</span>
                        </div>
                        <div class="d-flex justify-content-between small mb-1">
                            <span>VIP Tickets</span>
                            <span>{{postDetails.stats.vip.totalTickets}}</span>
                        </div>
                        <div class="vr vr-blurry position-absolute my-0 h-100 d-none d-md-block top-0 end-0"></div>
                    </div>
                    <div class="col-lg-3 col-md-6 mb-5 mb-md-0 position-relative p-4 rounded shadow-sm bg-white">
                        <i class="fas fa-ticket fa-3x text-primary mb-4 d-block text-center"></i>
                        <h5 class="text-primary fw-bold text-center mb-3">{{postDetails.stats.purchased.totalTickets}}
                            <span
                                class="small text-muted">({{((postDetails.stats.purchased.totalTickets)/(postDetails.stats.total.totalTickets))
                                | percent}})</span></h5>
                        <h6 class="fw-normal text-center mt-3">Purchased Tickets</h6>
                        <div class="d-flex justify-content-between small mb-1">
                            <span>Orders</span>
                            <span>{{postDetails.stats.purchased.totalOrders | number: '1.0' }}</span>
                        </div>
                        <hr class="hr mt-2 pt-0 mb-2 pb-0" />
                        <div class="d-flex justify-content-between small mb-1">
                            <span>Average</span>
                            <span>{{postDetails.stats.purchased.average | number: '1.1-1' }}</span>
                        </div>
                        <div class="d-flex justify-content-between small mb-1">
                            <span>Median</span>
                            <span>{{postDetails.stats.purchased.median | number: '1.1-1' }}</span>
                        </div>
                        <div class="d-flex justify-content-between small mb-1">
                            <span>Min / Max</span>
                            <span>{{postDetails.stats.purchased.min | number: '1.0' }} /
                                {{postDetails.stats.purchased.max | number: '1.0' }}</span>
                        </div>
                        <div class="vr vr-blurry position-absolute my-0 h-100 d-none d-md-block top-0 end-0"></div>
                    </div>

                    <div class="col-lg-3 col-md-6 mb-5 mb-md-0 position-relative p-4 rounded shadow-sm bg-white">
                        <i class="fas fa-ticket fa-3x text-primary mb-4 d-block text-center"></i>
                        <h5 class="text-primary fw-bold text-center mb-3">{{postDetails.stats.comp.totalTickets}} <span
                                class="small text-muted">({{((postDetails.stats.comp.totalTickets)/(postDetails.stats.total.totalTickets))
                                | percent}})</span></h5>
                        <h6 class="fw-normal text-center mt-3">Comp Tickets</h6>
                        <div class="d-flex justify-content-between small mb-1">
                            <span>Orders</span>
                            <span>{{postDetails.stats.comp.totalOrders | number: '1.0' }}</span>
                        </div>
                        <hr class="hr mt-2 pt-0 mb-2 pb-0" />
                        <div class="d-flex justify-content-between small mb-1">
                            <span>Average</span>
                            <span>{{postDetails.stats.comp.average | number: '1.1-1' }}</span>
                        </div>
                        <div class="d-flex justify-content-between small mb-1">
                            <span>Median</span>
                            <span>{{postDetails.stats.comp.median | number: '1.1-1' }}</span>
                        </div>
                        <div class="d-flex justify-content-between small mb-1">
                            <span>Min / Max</span>
                            <span>{{postDetails.stats.comp.min | number: '1.0' }} / {{postDetails.stats.comp.max |
                                number: '1.0' }}</span>
                        </div>
                        <div class="vr vr-blurry position-absolute my-0 h-100 d-none d-md-block top-0 end-0"></div>
                    </div>


                    <div class="col-lg-3 col-md-6 mb-5 mb-md-0 position-relative p-4 rounded shadow-sm bg-white">
                        <i class="fas fa-money-bill-wave fa-3x text-primary mb-4 d-block text-center"></i>
                        <h5 class="text-primary fw-bold text-center mb-3">
                            {{(postDetails.stats.purchased.ticketValue/100) | currency }}*</h5>
                        <h6 class="fw-normal text-center mt-3">Gross Revenue</h6>
                        <div class="d-flex justify-content-between small mb-1">
                            <span>Potential Revenue</span>
                            <span>{{((postDetails.stats.total.ticketValue / 100) | currency)}}</span>
                        </div>
                        <div class="d-flex justify-content-between small mb-1">
                            <span>Complementary Tickets</span>
                            <span>({{(postDetails.stats.comp.ticketValue / 100) | currency }})</span>
                        </div>
                        <div class="d-flex justify-content-between small mb-1">
                            <span>Purchased Tickets</span>
                            <span>{{(postDetails.stats.purchased.ticketValue / 100) | currency}}</span>
                        </div>
                        <div class="mt-2 small text-muted">* = Fees not included. See the <a
                                [href]="'/communities/'+communityDetails.id+'/manage/billing'">billing</a> section for
                            more details.</div>
                        <div class="vr vr-blurry position-absolute my-0 h-100 d-none d-md-block top-0 end-0"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-7 col-lg-8 col-xl-8">

                        <div class="card">
                            <app-seatmap-view [maxSelectable]="9999" [backgroundOffsetX]="1" [backgroundOffsetY]="1"
                            [seatData]="seatData" [backgroundImg]="seatmapBackgroundImg" [isLoadingSeatData]="false"
                            (selectSeat)="selectSeat($event)" (outputError)="errorToast($event)"
                            [selectedSeats]="selectedSeats"></app-seatmap-view>
                        <div class="mt-2 mb-2 text-center small fst-italic">Select seat to view order details</div>
                        <div class="d-flex justify-content-center align-items-center small mb-2">
                            <div style="display: inline-block; width: 15px; height: 15px; background-color: #ffcc00; margin-right: 5px; border: 1px solid #ffeb99;"></div> 
                            <span class="me-3">Comp Tickets</span>
                            <div style="display: inline-block; width: 15px; height: 15px; background-color: #00cc00; margin-right: 5px; border: 1px solid #c0f2c0;"></div> 
                            <span class="me-3">Purchased Tickets</span>
                            <div style="display: inline-block; width: 15px; height: 15px; background-color: #a3a3a3; margin-right: 5px; border: 1px solid #c8c8c8;"></div> 
                            <span>Available Tickets</span>
                        </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-5 col-lg-4 col-xl-4">

                        <div class="card" mdbScrollbar style="max-height: 650px; overflow: auto;">
                            <div class="text-center small text-muted">Ticket Availability ({{(eventLimit - (postDetails.stats.total.totalTickets - postDetails.stats.vip.totalTickets))}} / {{eventLimit}})</div>
                            <div class="card-body">
                              <div *ngFor="let seatStat of postDetails.seatStats" class="mb-2">
                                <div class="progress">
                                  <div
                                    class="progress-bar"
                                    [ngClass]="{
                                      'bg-success': ((seatStat.total - seatStat.issued) / seatStat.total) > 0.7,
                                      'bg-warning': ((seatStat.total - seatStat.issued) / seatStat.total) > 0.3 && ((seatStat.total - seatStat.issued) / seatStat.total) <= 0.7,
                                      'bg-danger': ((seatStat.total - seatStat.issued) / seatStat.total) <= 0.3
                                    }"
                                    role="progressbar"
                                    [style.width.%]="(((seatStat.total - seatStat.issued) / seatStat.total) * 100)"
                                    [aria-valuenow]="(((seatStat.total - seatStat.issued) / seatStat.total) * 100)"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                                <div class="d-flex justify-content-between small mb-1 mt-1">
                                  <span><b>{{seatStat.section}}</b></span>
                                  <span><b>{{(seatStat.total - seatStat.issued)}} / {{seatStat.total}}</b></span>
                                </div>
                                <div *ngFor="let sectionStat of seatStat.data" class="ms-4 mb-1">
                                  <div class="d-flex justify-content-between small">
                                    <span>{{sectionStat.priceClass}}</span>
                                    <span>
                                      {{sectionStat.available}} / {{sectionStat.total}}
                                      <span class="small text-muted">
                                        ({{((sectionStat.available)) / sectionStat.total | percent}})
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          

                    </div>
                </div>
            </ng-container>
            <ng-template #displayEventLoading>
                <ng-container *ngIf="!errorEvent; else displayErrorEvent">
                    <app-loading></app-loading>
                </ng-container>
                <ng-template #displayErrorEvent>
                    <app-https-error [error]="errorEvent"></app-https-error>
                </ng-template>
            </ng-template>
        </div>
    </ng-container>
</ng-container>
<ng-template #displaySkeleton>
    <ng-container *ngIf="!error; else displayError">
        <div class="row">
            <div *ngFor="let item of [].constructor(6); let i = index" class="col-xs-12 col-md-6 col-lg-4 col-xl-4">
                <app-list-item-loading></app-list-item-loading><br>
            </div>
        </div>
    </ng-container>
    <ng-template #displayError>
        <app-https-error [error]="error"></app-https-error>
    </ng-template>
</ng-template>