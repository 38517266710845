import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeToggleService {
  isDarkMode = new BehaviorSubject<boolean>(this.checkCurrentTheme());
  
  constructor() {}

  toggleTheme(): void {
    document.body.classList.toggle('dark');
    this.isDarkMode.next(this.checkCurrentTheme());

  }

  checkCurrentTheme(): boolean {
    return document.body.classList.contains('dark');
  }
  
  enableWakeLock() {
    const nav: any = navigator;
    if("wakeLock" in navigator) {
      nav["wakeLock"].request("screen")
    }
  }

}
