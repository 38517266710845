<div class="p-5 text-center bg-gradient-animation bg-image" style="height: 200px;">
    <div class="mask">
        <div class="d-flex justify-content-center align-items-center h-100">
            <div class="text-white">
                <h4 class="display-4 mb-3">Admin Area</h4>
            </div>
        </div>
    </div>
</div>
<section class="">
    <div class="container text-center text-md-start mt-5">
        <!-- Grid row -->
        <div class="row mt-3">
            <!-- Grid column -->
            <div class="col-md-12 col-lg-12 col-xl-12 mx-auto mb-8">
                <!-- Content -->
                <ng-container *ngIf="showUserSearch; else showUserProfile">
                    <ng-container *ngIf="!loading; else displayLoading">
                        <app-search engine="users" (output)="openLink($event)"></app-search>
                    </ng-container>
                    <ng-template #displayLoading>
                        <ng-container *ngIf="!error; else displayError">
                            <app-loading></app-loading>
                        </ng-container>
                        <ng-template #displayError>
                            <app-https-error [error]="error"></app-https-error>
                        </ng-template>
                    </ng-template>
                </ng-container>
                <ng-template #showUserProfile>
                    <div (click)="resetSearch()" class="pointer hoverUnderline"><i class="fas fa-arrow-left"></i> Select another user</div><br>
                    <!-- User Profile Info -->
                    <div>
                        <div class="d-flex">
                            <div class="p-2">
                                <div class="d-flex align-items-center">
                                    <ng-container *ngIf="selectedUserDetails.profilepic; else noProfilePic">
                                        <img referrerPolicy="no-referrer" [src]="selectedUserDetails.profilepic.raw"
                                            alt="" style="width: 45px; height: 45px;" class="rounded-circle"
                                            (error)="selectedUserDetails.profilepic.raw = './assets/user-profile.png'" />
                                    </ng-container>
                                    <ng-template #noProfilePic>
                                        <img referrerPolicy="no-referrer" alt="" style="width: 45px; height: 45px;"
                                            class="rounded-circle" [src]="'./assets/user-profile.png'" />
                                    </ng-template>

                                </div>
                            </div>
                            <div class="p-2 flex-grow-1">
                                <div>
                                    <h6 [innerHtml]="selectedUserDetails.displayname.snippet"
                                        class="noEmStyling result-content"></h6>
                                    <p *ngIf="selectedUserDetails.provider" class="text-muted mb-0"><app-provider-logo
                                            [provider]="selectedUserDetails.provider.raw"></app-provider-logo></p>
                                    <div class="small">{{selectedUserDetails.uid.raw}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- MDB Tabs -->
                    <!-- TABS GO HERE -->

                    <mdb-tabs #tabs [justified]="true">
                        <mdb-tab title="Tickets">
                            <ng-template mdbTabContent>
                                <div class="card-body">
                                    Tickets
                                    <app-user-tickets-list [lookupUid]="selectedUserDetails.id.raw"></app-user-tickets-list>
                                </div>
                            </ng-template>
                        </mdb-tab>
                        <mdb-tab title="Videos">
                            <ng-template mdbTabContent>
                                <div class="card-body">
                                    <app-user-video-list [lookupUid]="selectedUserDetails.id.raw"></app-user-video-list>
                                </div>
                            </ng-template>
                        </mdb-tab>
                        <mdb-tab title="Video Views">
                            <ng-template mdbTabContent>
                                <div class="card-body">
                                    <app-user-video-view-list [lookupUid]="selectedUserDetails.id.raw"></app-user-video-view-list>
                                </div>
                            </ng-template>
                        </mdb-tab>
                        <mdb-tab title="Purchases">
                            <ng-template mdbTabContent>
                                <div class="card-body">
                                    <app-user-purchase-list
                                        [lookupUid]="selectedUserDetails.id.raw"></app-user-purchase-list>
                                </div>
                            </ng-template>
                        </mdb-tab>
                        <mdb-tab title="Messages">
                            <ng-template mdbTabContent>
                                <div class="card-body">
                                    <app-user-messages-list
                                        [lookupUid]="selectedUserDetails.id.raw"></app-user-messages-list>
                                </div>
                            </ng-template>
                        </mdb-tab>
                    </mdb-tabs>


                </ng-template>
            </div>
            <!-- Grid column -->
        </div>
        <!-- Grid row -->
    </div>
</section>