import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  loading: boolean = false;
  showUserSearch: boolean = true;
  selectedUserDetails
  overviewDetails
  error
  constructor(
    private route: ActivatedRoute,
    private location: Location,
  ) { }

  ngOnInit(): void {
    console
    this.ngOnChanges()
  }

  ngOnChanges() {
    this.route.queryParams
      .subscribe(params => {
        const uid = params.uid
        if (uid) {
          this.lookupUserDetails(uid)
          this.location.replaceState('/admin?uid=' + uid)
        }
      })
  }


  openLink($event) {
    console.log('opening event', $event)
    this.location.replaceState('/admin?uid=' + $event.id)
    this.selectedUserDetails = $event.result
    this.showUserSearch = false
  }

  lookupUserDetails(uid: string) {
    console.log('look up user details')
    
  }

  resetSearch() {
    this.selectedUserDetails = null
    this.showUserSearch = true
  }

}
