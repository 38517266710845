<section class="">
  <div class="container text-center text-md-start mt-3">
    <!-- Grid row -->
    <div class="row">
      <!-- Grid column -->
      <div>
        <!-- Content -->
        <ng-container *ngIf="!loading; else displaySkeleton">
          <div class="row">
            <ngx-glide #ngxGlide [perView]="perView"
            [bound]="true"
            focusAt="0"
            [rewind]="false"
            swipeThreshold="50"
            dragThreshold="100"
            [showArrows]="tickets.length>perView"
            [showBullets]="tickets.length>perView"
            [arrowLeftTemplate]="arrowLeftTemplate"
            [arrowRightTemplate]="arrowRightTemplate"
            class="mb-2"
            >

              <ng-container *ngFor="let ticket of tickets; let i = index" class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4">
                <div class="card mt-3 mb-8 noselect" [ngStyle]="{'background-color': ticket.theme.bgColor, 'color': ticket.theme.textColor}">
                  <div class="">
                    <div class="text-start">
                      <ng-container *ngIf="ticket.theme.wideLogo; else showGenericHeader">
                        <img [src]="ticket.theme.wideLogo" style="width: 40%" class="mt-3 ms-3 mb-1">
                      </ng-container>
                      <ng-template #showGenericHeader>
                        <h6>{{event.venueName}}</h6>
                      </ng-template>
                      <div [ngStyle]="{'border': '0.5px solid ' + ticket.theme.accentColor, 'width': '100%'}" class="mt-2 mb-3"></div>
                      <div class="mt-3 ms-3">
                        <h6 class="mb-2" style="margin: 0px !important; padding: 0px !important;">{{venueDetails.name}}</h6>
                        <h3 class="mb-2 display-7">{{event.name}}</h3>
                      </div>
                      <div class="d-flex align-items-start ms-3 me-3">
                        <div class="col text-start">
                          <span class="small">Date</span>
                          <h6>{{event.startTime | date:'mediumDate'}}</h6>
                        </div>
                        <div class="col text-end">
                          <span class="small">Time</span>
                          <h6>{{event.startTime | date:'shortTime'}}</h6>
                        </div>
                      </div>
                      </div>
                    <div [ngStyle]="{'border': '0.5px solid ' + ticket.theme.accentColor, 'width': '100%'}" class="mt-1 mb-2"></div>
                      <div class="ms-3 me-3">
                        <div class="d-flex align-items-start">
                          <div class="col text-start">
                            <span class="small">Section</span>
                            <h5>{{ticket.section}}</h5>
                          </div>
                          <div class="col text-end">
                            <span class="small">Row / Seat</span>
                            <h5>{{ticket.row}} / {{ticket.seat}}</h5>
                          </div>
                        </div>
                      </div>
                    <div class="mt-5 text-center">
                      <div class="qrContainer text-center">
                        <ngx-qrcode-styling class="qrCode" [config]="config" [data]="ticket.id + '-' + validatorCode"></ngx-qrcode-styling>
                      </div>
                    </div>
                    <div class="text-center mb-3">
                      <h6 class="mt-3">{{ticket.type}}</h6>
                    </div>
                  </div>



                </div>
              </ng-container>
            </ngx-glide>
            <ng-template #arrowLeftTemplate>
              <div class="custom-arrow custom-arrow-left">
                <div class="btn btn-rounded btn-dark">
                  <i class="fas fa-chevron-left"></i>
                </div>
              </div>
            </ng-template>
            <ng-template #arrowRightTemplate>
              <div class="custom-arrow custom-arrow-right">
                <div class="btn btn-rounded btn-dark">
                  <i class="fas fa-chevron-right"></i>
                </div>
              </div>
            </ng-template>
          </div>

          <div class="row mb-5">
            <ng-container class="row" *ngIf="!loadingWalletLink; else loadingWallet">
              <div class="text-center">
                <a class="me-3" (click)="addToGoogleWallet()" role="button"><img class="walletIcon mb-2"
                    [src]="'./assets/add-to-google-wallet.svg'"></a>
                <a *ngIf="isSafari" class="me-3" (click)="addToAppleWallet()" role="button"><img class="walletIcon mb-2"
                    [src]="'./assets/add-to-apple-wallet.svg'"></a>
                <a *ngIf="!isSafari&&isAppleDevice" class="me-3" (click)="addToAppleWalletUnsupported()"
                  role="button"><img class="walletIcon mb-2" [src]="'./assets/add-to-apple-wallet.svg'"></a>
              </div>
            </ng-container>
            <ng-template #loadingWallet>
              <div class="text-center">
                <div class="mb-2"></div>
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading Wallet Link...</span>
                </div>
              </div>
            </ng-template>
          </div>
        </ng-container>
        <ng-template #displaySkeleton>
          <ng-container *ngIf="!error; else displayError">
            <div class="row">
              <app-loading></app-loading>
            </div>
          </ng-container>
          <ng-template #displayError>
            <app-https-error [error]="error"></app-https-error>
          </ng-template>
        </ng-template>
      </div>
      <!-- Grid column -->
    </div>
    <!-- Grid row -->
  </div>
</section>
