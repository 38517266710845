<ng-container *ngIf="loading; else notloading">
  <app-loading></app-loading>
</ng-container>
<ng-template #notloading>
  <form [formGroup]="productForm">
    <div class="card mb-3">
      <div class="card-body">
        <div *ngFor="let seat of selectedSeats; let i = index" class="mb-2">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 seatInfo-text">
              <h6>Section: {{seat.section}}</h6>
              <h6>Row: {{seat.row}}</h6>
              <h6>Seat: {{seat.seat}}</h6>
            </div>
            <div class="col-sm-12 col-md-8 col-lg-4 col-xl-4">
              <mdb-form-control [formGroupName]="seat.id">
                <mdb-select [formControlName]="'type'" [ngModelOptions]="{ standalone: true }">
                  <mdb-option *ngFor="let option of seat.priceArray" [value]="option.type" [label]="option.type">
                    {{ option.type }}
                    <span class="option-secondary-text">{{ (option.price/100) | currency }}</span>
                  </mdb-option>
                </mdb-select>
                <label mdbLabel class="form-label">Ticket Type</label>
              </mdb-form-control>
            </div>

            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 price-text">
              {{(productForm.controls[seat.id].value.price / 100) | currency}}
            </div>
          </div>
          <hr  class="hr" />
        </div>
        <div class="row">
          <div class="col price-text">
            <div>
              <span *ngFor="let ticketType of ticketTypeCounts" class="badge rounded-pill badge-light  mr-2">
                {{ ticketType.count }}x - {{ ticketType.type }}{{ ticketType.count > 1 ? 's' : '' }}
              </span>
            </div>
            <br>
            Ticket Total: <b>{{(totalPrice / 100) | currency}}</b>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
